import React, { useRef } from 'react';
import altImage from '../../resource/images/alt_image.png';
import styles from './InputImage.module.css';
import PropTypes from 'prop-types';

const InputImage = ({
  imageList,
  handleFileInputChange,
  deleteImage,
  isOnce,
}) => {
  const inputRefs = useRef([]);

  const openCamera = (index) =>
    inputRefs.current[index] && inputRefs.current[index].click();

  return (
    <div className={styles.imageContainer}>
      {imageList !== null && imageList.length !== 0
        ? imageList.map((image, index) => (
            <div key={index} className={styles.imageBoxWrapper}>
              <input
                id={`input-store-image-upload-${index}`}
                type='file'
                ref={(el) => (inputRefs.current[index] = el)}
                accept='.jpg, .jpeg, .png'
                onChange={(e) => handleFileInputChange(e, index)}
                style={{
                  position: 'absolute',
                  left: '-1000px',
                  visibility: 'hidden',
                }}
              />
              <img
                className={styles.afterImageContainer}
                onClick={() => openCamera(index)}
                src={image}
                alt={`uploaded-${index}`}
              />
              <div
                className={styles.deleteButton}
                onClick={() => deleteImage(index)}>
                삭제
              </div>
            </div>
          ))
        : isOnce && (
            <div className={styles.imageBoxWrapper}>
              <input
                id='input-store-image-upload-new'
                type='file'
                ref={(el) => (inputRefs.current[imageList.length] = el)}
                accept='.jpg, .jpeg, .png'
                onChange={(e) => handleFileInputChange(e, imageList.length)}
                style={{
                  position: 'absolute',
                  left: '-1000px',
                  visibility: 'hidden',
                }}
              />
              <label
                htmlFor='input-store-image-upload-new'
                className={styles.imageUploadButton}>
                <img
                  className={styles.profileBox}
                  src={altImage}
                  alt='upload-new'
                />
              </label>
            </div>
          )}
      {!isOnce && (
        <div className={styles.imageBoxWrapper}>
          <input
            id='input-store-image-upload-new'
            type='file'
            ref={(el) => (inputRefs.current[imageList.length] = el)}
            accept='.jpg, .jpeg, .png'
            onChange={(e) => handleFileInputChange(e, imageList.length)}
            style={{
              position: 'absolute',
              left: '-1000px',
              visibility: 'hidden',
            }}
          />
          <label
            htmlFor='input-store-image-upload-new'
            className={styles.imageUploadButton}>
            <img
              className={styles.profileBox}
              src={altImage}
              alt='upload-new'
            />
          </label>
        </div>
      )}
    </div>
  );
};

InputImage.propTypes = {
  imageList: PropTypes.array,
  handleFileInputChange: PropTypes.func,
  deleteImage: PropTypes.func,
  isOnce: PropTypes.bool,
  oneImage: PropTypes.string,
};

export default InputImage;
