import './App.css';
import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import routes from './routes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RouterProvider } from './RouterContext';
import { getAccessAdmin, signOut } from './network/api/AuthAPI';
import { login, logout } from './store/actions/authActions';
import Error from './components/error/Error';
import Navigator from './components/util/Navigator';
import './utils/scroll/styles.css';
import 'react-datepicker/dist/react-datepicker.css';

const getRoutes = (allRoutes, isAuthenticated) =>
  allRoutes.map((route) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }

    if (route.route) {
      if (route.private && !isAuthenticated) {
        return (
          <Route
            exact
            path={route.route}
            element={<Navigate to='/authentication/sign-in' replace />}
            key={route.key}
          />
        );
      } else {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }
    }

    return null;
  });

const App = ({ loginBy, logoutBy, isAuthenticated }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    loginCheck();
  }, []);

  const checkRefreshTokenInCookie = () => {
    let cookies = document.cookie.split(';');
    let cookiesKeys = [];
    for (let i = 0; i < cookies.length; i++) {
      cookiesKeys.push(cookies[i].split('=')[0]);
    }
    for (let i = 0; i < cookiesKeys.length; i++) {
      if (cookiesKeys[i].includes('refreshToken') === true) {
        return true;
      }
    }
  };

  const loginCheck = async () => {
    const response = await getAccessAdmin();
    if (response === false) {
      if (checkRefreshTokenInCookie()) {
        await signOut();
      } else {
        sessionStorage.clear();
        localStorage.clear();
        logoutBy();
      }
    } else {
      loginBy({
        name: response.name,
      });
    }
    setIsLoaded(true);
  };

  const ErrorFallBack = () => {
    return <Error />;
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallBack}>
      <>
        {isLoaded ? (
          <RouterProvider>
            <div className='app-container'>
              <Routes>
                {getRoutes(routes, isAuthenticated)}
                <Route path='*' element={<Navigate to='/user?page=1' />} />
              </Routes>
              <Navigator />
            </div>
          </RouterProvider>
        ) : null}
      </>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => ({
  loginBy: (userInfo) => dispatch(login(userInfo)),
  logoutBy: () => dispatch(logout()),
});

App.propTypes = {
  loginBy: PropTypes.func,
  logoutBy: PropTypes.func,
  isAuthenticated: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
