import { baseInstance, authInstance } from '../AxiosClient';

export const permitDogStatus = async (id) => {
  try {
    const { data } = await authInstance.post(`dog/permit/status`, id);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const rejectDogStatus = async (id) => {
  try {
    const { data } = await authInstance.post(`dog/reject/status`, id);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getDogList = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`dog/get`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getDogListWithSearch = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`dog/get/search`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const deleteDog = async (id) => {
  try {
    const { data } = await authInstance.delete(`dog/delete/${id}`);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const downloadDogExcel = async (excelParams) => {
  try {
    const { data } = await authInstance.get(`dog/download`, {
      params: excelParams,
      responseType: 'blob', // responseType을 'blob'으로 설정
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `반려로운_동물관리.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
