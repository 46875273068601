import React, { useRef, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import styles from './NoticeModal.module.css';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import {
  modifyNotice,
  registerNotice,
  uploadNoticeImage,
} from '../../network/api/NoticeAPI';
import CloseButton from '../../resource/images/svg/CloseButton';

Quill.register('modules/imageResize', ImageResize);

const NoticeModal = ({ notice, setIsModalOpen, setNotice }) => {
  const QuillRef = useRef(null);
  const [title, setTitle] = useState(notice !== null ? notice.title : '');
  const [content, setContent] = useState(notice !== null ? notice.content : '');
  const [isMain, setIsMain] = useState(notice !== null ? notice.main : false);

  const titleInputHandler = (e) => {
    setTitle(e.target.value);
  };

  const contentInputHandler = (value) => {
    setContent(value);
  };

  const registerClick = async () => {
    const response = await registerNotice({
      title: title,
      content: content,
      main: isMain,
    });
    if (response === 'success') {
      alert('등록 성공');
      window.location.reload(true);
    } else if (response === 'isMain') {
      alert('다른 홈화면 공지가 존재합니다.\n해제 후 등록해 주세요');
    }
  };

  const modifyClick = async () => {
    const response = await modifyNotice({
      id: notice.id,
      title: title,
      content: content,
      main: isMain,
    });
    if (response === 'success') {
      alert('수정 성공');
      window.location.reload(true);
    } else if (response === 'isMain') {
      alert('다른 홈화면 공지가 존재합니다.\n해제 후 등록해 주세요');
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setNotice(null);
  };

  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await uploadNoticeImage(formData);
        if (response !== false) {
          const imageUrl = response;
          // Quill 인스턴스 가져오기
          const editor = QuillRef.current.getEditor();
          const range = editor.getSelection();
          if (range) {
            const index = range.index;
            editor.insertEmbed(index, 'image', imageUrl);
            editor.setSelection(index + 1);
          } else {
            // 예외 처리: 범위가 유효하지 않을 때
            console.error('Quill 에디터의 커서 위치를 찾을 수 없습니다.');
          }
        }
      } catch (error) {
        console.error('이미지 업로드 실패:', error);
      }
    };
  };

  // 사용하고 싶은 옵션, 나열 되었으면 하는 순서대로 나열
  const toolbarOptions = [
    ['link', 'image'],
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
  ];

  // 옵션에 상응하는 포맷, 추가해주지 않으면 text editor에 적용된 스타일을 볼수 없음
  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'align',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'background',
    'color',
    'link',
    'image',
    'video',
    'width',
  ];

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: toolbarOptions,
        handlers: {
          image: imageHandler, // 이미지 핸들러를 등록
        },
      },

      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize', 'Toolbar'],
      },
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div
          className={styles.dialogCloseButton}
          onClick={() => {
            closeModal();
          }}>
          <CloseButton />
        </div>
        <div className={styles.dialogContainer}>
          <div className={styles.title}>
            {notice !== null ? '공지수정' : '공지등록'}
          </div>
          <label className={styles.agreeLabel}>
            <input
              type='checkbox'
              checked={isMain}
              onChange={() => {
                setIsMain(!isMain);
              }}
            />
            <span className={styles.checkMark}></span>
            <span className={styles.agreeAllText}>홈 메인 공지 등록</span>
          </label>
          <input
            type='text'
            className={styles.inputId}
            name='title'
            placeholder='제목'
            onChange={titleInputHandler}
            maxLength={80}
            value={title}
          />
          <ReactQuill
            ref={QuillRef}
            className={styles.quillEditor}
            value={content || ''}
            theme='snow'
            modules={{
              ...modules,
              imageResize: {
                parchment: Quill.import('parchment'),
                modules: ['Resize', 'DisplaySize', 'Toolbar'],
              },
            }}
            formats={formats}
            onChange={contentInputHandler}
            placeholder={'내용을 입력해 주세요'}
            preserveWhitespace
          />
          <div
            className={styles.button}
            onClick={() => {
              notice !== null ? modifyClick() : registerClick();
            }}>
            {notice !== null ? '수정하기' : '등록하기'}
          </div>
        </div>
      </div>
    </div>
  );
};

NoticeModal.propTypes = {
  notice: PropTypes.object,
  setIsModalOpen: PropTypes.func,
  setNotice: PropTypes.func,
};

export default NoticeModal;
