import React from 'react';

const LightGreyRightArrow = () => {
  return (
    <svg
      width='9'
      height='15'
      viewBox='0 0 9 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.14551 6.6665L2.20801 0.83317C1.79134 0.416503 1.16634 0.416503 0.749673 0.83317C0.333007 1.24984 0.333007 1.87484 0.749673 2.2915L5.85384 7.49984L0.749673 12.7082C0.333007 13.1248 0.333007 13.7498 0.749673 14.1665C0.958007 14.3748 1.16634 14.479 1.47884 14.479C1.79134 14.479 1.99967 14.3748 2.20801 14.1665L8.14551 8.33317C8.56217 7.81234 8.56217 7.18734 8.14551 6.6665C8.14551 6.77067 8.14551 6.77067 8.14551 6.6665Z'
        fill='#ADADAD'
      />
    </svg>
  );
};

export default LightGreyRightArrow;
