import React, { useState, useEffect } from 'react';
import {
  alertDialogToggle,
  setAlertDialogMessage,
} from '../../../store/actions/utilActions';
import { connect } from 'react-redux';
import styles from './SignIn.module.css';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { signIn } from '../../../network/api/AuthAPI';
import { login } from '../../../store/actions/authActions';
import PropTypes from 'prop-types';
import GreyLeftArrow from '../../../resource/images/svg/GreyLeftArrow';
import LoginLogo from '../../../resource/images/svg/LoginLogo';
import LoadingComponent from '../../util/LoadingComponent';

const SignIn = ({
  loginBy,
  alertDialogToggleBy,
  setAlertDialogMessageBy,
  isAuthenticated,
}) => {
  const navigate = useNavigate();
  const urlLocation = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [loginInfo, setLoginInfo] = useState({
    username: '',
    password: '',
    isAutoLogin: false,
  });

  const idInputHandler = (e) => {
    setLoginInfo((prevState) => ({
      ...prevState,
      username: e.target.value,
    }));
  };

  const passwordInputHandler = (e) => {
    setLoginInfo((prevState) => ({
      ...prevState,
      password: e.target.value,
    }));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      loginButtonClick();
    }
  };

  const loginButtonClick = async () => {
    setIsLoading(true);
    if (loginInfo.username !== '' && loginInfo.password !== '') {
      const response = await signIn(loginInfo);
      if (response !== false && response !== undefined) {
        loginBy({
          name: response.name,
        });
        setIsLoading(false);
        navigate('/user?page=1');
      } else {
        alert('아이디 또는 비밀번호가 일치하지 않습니다');
      }
    } else {
      alert('로그인 정보를 입력해주세요!');
    }
    setIsLoading(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.emptySpace} />
      <div className={styles.loginLogo}>
        <LoginLogo />
      </div>
      <div className={styles.loginTitle}>로그인</div>
      <input
        type='text'
        className={styles.inputId}
        name='name'
        placeholder='이메일'
        onChange={idInputHandler}
        onKeyDown={handleKeyDown}
        maxLength={35}
        value={loginInfo.username}
      />
      <input
        type='password'
        autoComplete='off'
        className={styles.inputPW}
        placeholder='비밀번호'
        maxLength={20}
        onChange={passwordInputHandler}
        onKeyDown={handleKeyDown}
        value={loginInfo.password}
      />
      <div className={styles.loginButton} onClick={() => loginButtonClick()}>
        로그인
      </div>
      <div className={styles.emptyBottomSpace} />
      {isLoading ? <LoadingComponent /> : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => ({
  loginBy: (userInfo) => dispatch(login(userInfo)),
  alertDialogToggleBy: () => dispatch(alertDialogToggle()),
  setAlertDialogMessageBy: (message) =>
    dispatch(setAlertDialogMessage(message)),
});

SignIn.propTypes = {
  loginBy: PropTypes.func,
  alertDialogToggleBy: PropTypes.func,
  setAlertDialogMessageBy: PropTypes.func,
  isAuthenticated: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
