import React, { useState } from 'react';
import styles from './MyPage.module.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { changePassword, checkAdmin } from '../../network/api/AdminAPI';
import { login } from '../../store/actions/authActions';
import { useNavigate } from 'react-router-dom';

const MyPage = ({ isAuthenticated, user }) => {
  const navigate = useNavigate();
  const [isAuthOk, setIsAuthOk] = useState(false);
  const [nowPassword, setNowPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const nowPasswordInputHandler = (e) => {
    setNowPassword(e.target.value);
  };

  const newPasswordInputHandler = (e) => {
    setNewPassword(e.target.value);
  };

  const confirmButtonClick = async () => {
    const response = await checkAdmin({ nowPassword: nowPassword });

    if (response === 'success') {
      setIsAuthOk(true);
    } else if (response === 'invalid') {
      alert('비밀번호가 일치하지 않습니다');
    } else {
      alert(
        '비밀번호 확인 중 오류가 발생하였습니다\n로그아웃 후 다시 시도해주세요'
      );
    }
  };

  const changeButtonClick = async () => {
    const regExp =
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*~_\-+=?])[a-zA-Z0-9!@#$%^&*~_\-+=?]{8,20}$/;

    // 비밀번호가 입력되지 않았거나 형식이 맞지 않을 때
    if (!newPassword) {
      return alert('비밀번호를 입력해 주세요');
    }
    if (!regExp.test(newPassword)) {
      return alert('비밀번호 형식에 맞게 입력해 주세요');
    }

    const response = await changePassword({
      nowPassword: nowPassword,
      newPassword: newPassword,
    });

    switch (response) {
      case 'success':
        alert('비밀번호 변경이 완료되었습니다');
        return navigate(-1);
      case 'invalid':
        return alert(
          '비밀번호가 변경에 실패하였습니다\n새로고침 후 다시 시도해주세요'
        );
      case 'disMatch':
        return alert('비밀번호 형식에 맞게 입력해 주세요');
      default:
        return alert(
          '비밀번호 확인 중 오류가 발생하였습니다\n로그아웃 후 다시 시도해주세요'
        );
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        {isAuthOk ? (
          <>
            <h3>관리자 정보</h3>
            <div className={styles.categoryTitle}>관리자명 - {user.name}</div>
            <div className={styles.categoryTitle}>비밀번호 변경하기</div>
            <input
              id='new'
              type='password'
              autoComplete='off'
              className={styles.inputPW}
              placeholder='영문, 숫자, 특수문자 조합 최소 8자'
              maxLength={20}
              onChange={newPasswordInputHandler}
              value={newPassword}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  changeButtonClick(); // 엔터 키를 눌렀을 때 호출
                }
              }}
            />

            <div
              className={styles.button}
              onClick={() => {
                changeButtonClick();
              }}>
              변경하기
            </div>
          </>
        ) : (
          <>
            <h3>비밀번호 확인</h3>
            <h5>
              회원님의 개인정보를 안전하게 보호하기 위해 비밀번호를 다시 한번
              확인합니다.
            </h5>
            <div className={styles.categoryTitle}>기존 비밀번호</div>
            <input
              id='now'
              type='password'
              autoComplete='off'
              className={styles.inputPW}
              placeholder='영문, 숫자, 특수문자 조합 최소 8자'
              maxLength={20}
              onChange={nowPasswordInputHandler}
              value={nowPassword}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  confirmButtonClick(); // 엔터 키를 눌렀을 때 호출
                }
              }}
            />

            <div
              className={styles.button}
              onClick={() => {
                confirmButtonClick();
              }}>
              확인하기
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

MyPage.propTypes = {
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object,
  loginBy: PropTypes.func,
};

export default connect(stateToProps, null)(MyPage);
