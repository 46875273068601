import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import altImage from '../../resource/images/alt_image.png';
import { sendPushAll } from '../../network/api/AlarmAPI';
import styles from './AlarmSendAllDialog.module.css';
import PropTypes from 'prop-types';
import CloseButton from '../../resource/images/svg/CloseButton';
import { compressImage } from '../../utils/compressImage';

let imageFileBlob = null;

const AlarmSendAllDialog = ({ setIsSendAllDialogOpen }) => {
  const inputRef = useRef(null);
  const [isAd, setIsAd] = useState(false);
  const [title, setTitle] = useState('');
  const [alarmContent, setAlarmContent] = useState('');
  const [image, setImage] = useState(null);
  const [url, setUrl] = useState('');

  const openCamera = () => inputRef.current && inputRef.current.click();

  useEffect(() => {
    return () => {
      imageFileBlob = null;
    };
  }, []);

  const titleInputHandler = (e) => {
    setTitle(e.target.value);
  };

  const urlInputHandler = (e) => {
    setUrl(e.target.value);
  };

  const noticeInputHandler = (e) => {
    setAlarmContent(e.target.value);
  };

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 1 * 1024 * 1024) {
        alert('파일 크기는 1MB 이하여야 합니다.');
      } else {
        const compressedImage = await compressImage(file);
        const fileURL = URL.createObjectURL(compressedImage);
        imageFileBlob = compressedImage;
        setImage(fileURL);
      }
    }
    event.target.value = '';
  };

  const registerButtonClick = async () => {
    if (title !== '' && alarmContent !== '') {
      const formData = new FormData();
      formData.append('multipartFile', imageFileBlob);

      const uploaderString = JSON.stringify({
        isAdPush: isAd,
        data: {
          title: title,
          body: alarmContent,
          url: url,
        },
      });

      formData.append(
        'requestDto',
        new Blob([uploaderString], { type: 'application/json' })
      );

      const response = await sendPushAll(formData);
      if (response === 'success') {
        window.location.reload(true);
      } else if (response === 'sizeOver') {
        alert('이미지 용량이 초과되었습니다');
      } else {
        alert('등록실패');
      }
    } else {
      alert('입력항목들을 모두 입력해 주세요 ');
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div
          className={styles.dialogCloseButton}
          onClick={() => {
            setIsSendAllDialogOpen(false);
          }}>
          <CloseButton />
        </div>
        <div className={styles.dialogContainer}>
          <label className={styles.agreeLabel}>
            <input
              type='checkbox'
              checked={isAd}
              onChange={() => {
                setIsAd(!isAd);
              }}
            />
            <span className={styles.checkMark}></span>
            <span className={styles.agreeAllText}>광고성 푸시</span>
          </label>
          <div className={styles.inputTitle}>알림제목 (50자 이내)</div>
          <input
            type='text'
            className={styles.inputId}
            placeholder='알림제목'
            onChange={titleInputHandler}
            maxLength={50}
            value={title}
          />
          <div>{title.length}/50</div>
          <div className={styles.inputTitle}>푸시알림 클릭링크</div>
          <input
            type='text'
            className={styles.inputId}
            placeholder='푸시알림 클릭링크'
            onChange={urlInputHandler}
            value={url}
          />
          <div className={styles.inputTitle}>배너 이미지 (1MB 이하)</div>
          <div className={styles.imageContainer}>
            {image !== null && image !== '' ? (
              <>
                <input
                  id='input-store-image-upload'
                  type='file'
                  ref={inputRef}
                  accept='.jpg, .jpeg, .png'
                  onChange={handleFileInputChange}
                  style={{
                    position: 'absolute',
                    left: '-1000px',
                    visibility: 'hidden',
                  }}
                />
                <img
                  className={styles.afterImageContainer}
                  onClick={openCamera}
                  src={image}
                />
              </>
            ) : (
              <>
                <input
                  id='input-store-image-upload'
                  type='file'
                  ref={inputRef}
                  accept='.jpg, .jpeg, .png'
                  onChange={handleFileInputChange}
                  style={{
                    position: 'absolute',
                    left: '-1000px',
                    visibility: 'hidden',
                  }}
                />
                <label
                  htmlFor='input-store-image-upload'
                  className={styles.imageUploadButton}>
                  <img className={styles.profileBox} src={altImage} />
                </label>
              </>
            )}
          </div>
          <div className={styles.inputTitle}>알림내용 (200자 이내)</div>
          <input
            type='text'
            className={styles.inputId}
            placeholder='알림내용을 입력하세요'
            onChange={noticeInputHandler}
            maxLength={200}
            value={alarmContent}
          />
          <div>{alarmContent.length}/200</div>
          <div
            className={styles.loginButton}
            onClick={() => {
              registerButtonClick();
            }}>
            전송하기
          </div>
        </div>
      </div>
    </div>
  );
};

AlarmSendAllDialog.propTypes = {
  setIsSendAllDialogOpen: PropTypes.func,
};

export default AlarmSendAllDialog;
