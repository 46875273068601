import { baseInstance, authInstance } from '../AxiosClient';

export const getMachine = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`machine/get`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const registerMachine = async (machieInfo) => {
  try {
    const { data } = await authInstance.post(`machine/register`, machieInfo);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const modifyMachine = async (machieInfo) => {
  try {
    const { data } = await authInstance.post(`machine/modify`, machieInfo);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getMachineLog = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`machine/get/log/admin`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getMachineLogDetail = async (info) => {
  try {
    const { data } = await authInstance.get(`machine/get/log/detail/admin`, {
      params: info,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const deleteMachineLog = async (id) => {
  try {
    const { data } = await authInstance.delete(`machine/log/${id}`);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getAdvertisementList = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`machine/admin/ad`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const registerAd = async (adInfo) => {
  try {
    const { data } = await authInstance.post(`machine/admin/ad`, adInfo);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const modifyAd = async (adInfo) => {
  try {
    const { data } = await authInstance.put(`machine/admin/ad`, adInfo);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const deleteAd = async (id) => {
  try {
    const { data } = await authInstance.delete(`machine/admin/ad/${id}`);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const downloadServiceLogExcel = async (excelParams) => {
  try {
    const { data } = await authInstance.get(`machine/download`, {
      params: excelParams,
      responseType: 'blob', // responseType을 'blob'으로 설정
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `서비스 이용내역.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
