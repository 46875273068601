import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import Paging from '../util/Paging';
import styles from './Report.module.css';
import {
  getReportList,
  deleteReport,
  getReportListNickname,
} from '../../network/api/ReportAPI';
import ImageDialog from '../util/ImageDialog';
import ExpirationSettingDialog from './ExpirationSettingDialog';
import { dateChanger } from '../../utils/converter';

const Report = () => {
  const navigate = useNavigate();
  const [reportList, setReportList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [imageUrl, setImageUrl] = useState(null);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isSettingDialogOpen, setIsSettingDialogOpen] = useState(false);

  const { page } = useParams();
  let intPage = parseInt(page);

  useEffect(() => {
    getReportListInit();
  }, [intPage]);

  const getReportListInit = async () => {
    const response = await getReportList({
      page: intPage,
      size: 10,
    });
    if (response) {
      setReportList(response.content);
      setTotalCount(response.totalElements);
    }
  };

  const searchInputHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchClick();
    }
  };

  const searchClick = async () => {
    intPage = 1;
    if (searchValue !== '') {
      const response = await getReportListNickname({
        page: intPage,
        size: 10,
        nickname: searchValue,
      });
      if (response) {
        if (response === undefined) {
          return;
        }
        try {
          setReportList(response.content);
          setTotalCount(response.totalElements);
        } catch (error) {
          console.error('데이터 불러오기 실패:', error);
        }
      }
    } else {
      getReportListInit();
    }
  };

  const clickOpenDialog = (imageUrl) => {
    setImageUrl(imageUrl);
    setIsImageDialogOpen(true);
  };

  const clickDeleteDialog = (id) => {
    const result = window.confirm('정말 삭제하시겠습니까?');

    if (result) {
      deleteVisitPlaceFunc(id);
    } else {
      return;
    }
  };

  const deleteVisitPlaceFunc = async (id) => {
    const response = await deleteReport({ id: id });
    if (response === 'success') {
      alert('삭제완료');
      window.location.reload(true);
    } else {
      alert('삭제실패');
    }
  };

  const handlePageChange = async (page) => {
    navigate(`/report/${page}`);
  };

  return (
    <div className={styles.wrapper}>
      {isImageDialogOpen && imageUrl !== null ? (
        <ImageDialog
          setIsImageDialogOpen={setIsImageDialogOpen}
          imageUrl={imageUrl}
          type='image'
        />
      ) : null}
      {isSettingDialogOpen ? (
        <ExpirationSettingDialog
          setIsSettingDialogOpen={setIsSettingDialogOpen}
        />
      ) : null}
      <div className={styles.innerWrapper}>
        <div className={styles.titleText}>제보 관리</div>

        <div className={styles.filterSection}>
          <div>
            <input
              type='text'
              className={styles.searchBox}
              placeholder='제보자 닉네임 입력'
              onChange={searchInputHandler}
              onKeyDown={handleKeyDown}
              value={searchValue}
            />
          </div>
          <div
            className={styles.searchButton}
            onClick={() => {
              searchClick();
            }}>
            검색
          </div>
          <div
            className={styles.searchButton}
            onClick={() => {
              setIsSettingDialogOpen(true);
            }}>
            제보된 핀 유효기한 설정
          </div>
        </div>
        <div className={styles.listContainer}>
          <div className={styles.totalCount}>전체 : {totalCount} 건</div>
        </div>
        <table className={styles.customTable}>
          <colgroup>
            <col style={{ minWidth: '120px' }}></col>
            <col style={{ minWidth: '120px' }}></col>
            <col style={{ minWidth: '150px' }}></col>
            <col style={{ minWidth: '150px' }}></col>
            <col style={{ minWidth: '150px' }}></col>
            <col style={{ minWidth: '80px' }}></col>
          </colgroup>
          <thead>
            <tr>
              <th>제보자 닉네임</th>
              <th>제보 이미지</th>
              <th>도로명주소</th>
              <th>지번주소</th>
              <th>제보시각</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {reportList.map((item, index) => (
              <tr key={index}>
                <td>{item.userNickname}</td>
                <td>
                  <button
                    className={styles.button}
                    onClick={() => {
                      clickOpenDialog(item.imageUrl);
                    }}>
                    이미지 보기
                  </button>
                </td>
                <td>{item.roadAddress}</td>
                <td>{item.jibunAddress}</td>
                <td>{dateChanger(item.reportTime)}</td>
                <td>
                  <button
                    className={styles.rbutton}
                    onClick={() => clickDeleteDialog(item.id)}>
                    삭제
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.pagingContainer}>
          <Paging
            page={intPage}
            countPerPage={10}
            pageRangeDisplayed={5}
            count={totalCount}
            setPage={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Report;
