import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Paging from '../util/Paging';
import styles from './Alarm.module.css';
import {
  getAllAlarmList,
  getAllAlarmListByNickname,
} from '../../network/api/AlarmAPI';
import ImageDialog from '../util/ImageDialog';
import AlarmSendAllDialog from './AlarmSendAllDialog';
import { dateChanger } from '../../utils/converter';

const Alarm = () => {
  const location = useLocation();
  let previousState = location.state;
  const navigate = useNavigate();
  const [alarmList, setAlarmList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [imageUrl, setImageUrl] = useState(null);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [isAll, setIsAll] = useState(false);
  const [isSendAllDialogOpen, setIsSendAllDialogOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [prev, setPrev] = useState(previousState);
  const { page } = useParams();
  let intPage = parseInt(page);

  useEffect(() => {
    getReportListInit(previousState);
  }, [intPage]);

  const getReportListInit = async (previousStateProps) => {
    switch (previousStateProps) {
      case 'none': {
        const response = await getAllAlarmList({
          page: intPage,
          size: 10,
        });
        if (response) {
          setPrev('none');
          setIsAll(false);
          setAlarmList(response.content);
          setTotalCount(response.totalElements);
        }
        break;
      }
      case null: {
        const response = await getAllAlarmList({
          page: intPage,
          size: 10,
        });
        if (response) {
          setPrev('none');
          setAlarmList(response.content);
          setTotalCount(response.totalElements);
        }
        break;
      }
      case 'all': {
        const response = await getAllAlarmListByNickname({
          page: intPage,
          size: 10,
          nickname: 'all',
        });
        if (response) {
          if (response === undefined) {
            return;
          }
          try {
            setPrev('all');
            setIsAll(true);
            setAlarmList(response.content);
            setTotalCount(response.totalElements);
          } catch (error) {
            console.error('데이터 불러오기 실패:', error);
          }
        }
        break;
      }
      default: {
        const response = await getAllAlarmListByNickname({
          page: intPage,
          size: 10,
          nickname: previousStateProps,
        });
        if (response) {
          if (response === undefined) {
            return;
          }
          try {
            setPrev(previousStateProps);
            setAlarmList(response.content);
            setTotalCount(response.totalElements);
          } catch (error) {
            console.error('데이터 불러오기 실패:', error);
          }
        }
      }
    }
  };

  const searchInputHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchClick();
    }
  };

  const searchClick = async () => {
    if (searchValue !== '') {
      if (intPage === 1) {
        getReportListInit(searchValue);
      } else {
        navigate(`/alarm/1`, { state: searchValue });
      }
    } else {
      getReportListInit('none');
    }
  };

  const allClick = async () => {
    if (isAll) {
      if (intPage === 1) {
        getReportListInit('none');
      } else {
        navigate(`/alarm/1`, { state: 'none' });
      }
    } else {
      if (intPage === 1) {
        getReportListInit('all');
      } else {
        navigate(`/alarm/1`, { state: 'all' });
      }
    }
  };

  const clickOpenDialog = (imageUrl) => {
    if (imageUrl !== null && imageUrl !== '') {
      setImageUrl(imageUrl);
      setIsImageDialogOpen(true);
    } else {
      alert('등록된 이미지가 없습니다');
    }
  };

  const handlePageChange = async (page) => {
    navigate(`/alarm/${page}`, { state: prev });
  };

  return (
    <div className={styles.wrapper}>
      {isImageDialogOpen && imageUrl !== null ? (
        <ImageDialog
          setIsImageDialogOpen={setIsImageDialogOpen}
          imageUrl={imageUrl}
          type='image'
        />
      ) : null}
      {isSendAllDialogOpen ? (
        <AlarmSendAllDialog setIsSendAllDialogOpen={setIsSendAllDialogOpen} />
      ) : null}
      <div className={styles.innerWrapper}>
        <div className={styles.titleText}>푸시 알림 관리</div>
        <div className={styles.aboveContainer}>
          <div>
            <input
              type='text'
              className={styles.searchInput}
              placeholder='수신자 닉네임'
              onChange={searchInputHandler}
              onKeyDown={handleKeyDown}
              value={searchValue}
            />
          </div>
          <div
            className={styles.searchButton}
            onClick={() => {
              searchClick();
            }}>
            검색
          </div>
          <div
            className={isAll ? styles.searchButtonSelect : styles.searchButton}
            onClick={() => {
              allClick();
            }}>
            전체 수신 메시지 보기
          </div>
          <div
            className={styles.searchButton}
            onClick={() => {
              setIsSendAllDialogOpen(true);
            }}>
            전체 알림전송
          </div>
        </div>
        <div className={styles.listContainer}>
          <div className={styles.totalCount}>전체 : {totalCount} 건</div>
        </div>
        <table className={styles.customTable}>
          <colgroup>
            <col style={{ minWidth: '150px' }}></col>
            <col style={{ minWidth: '200px' }}></col>
            <col style={{ minWidth: '200px' }}></col>
            <col style={{ minWidth: '170px' }}></col>
            <col style={{ minWidth: '170px' }}></col>
            <col style={{ minWidth: '110px' }}></col>
          </colgroup>
          <thead>
            <tr>
              <td>수신자</td>
              <td>알림제목</td>
              <td>알림내용</td>
              <td>이미지보기</td>
              <td>전송날짜</td>
              <td>읽음여부</td>
            </tr>
          </thead>
          <tbody>
            {alarmList.map((item, index) => (
              <tr key={index}>
                <td>{item.nickname === 'all' ? '전체' : item.nickname}</td>
                <td>{item.title}</td>

                <td
                  dangerouslySetInnerHTML={{
                    __html: item.content,
                  }}></td>
                <td>
                  <button
                    className={styles.button}
                    onClick={() => {
                      clickOpenDialog(item.imageUrl);
                    }}>
                    이미지 보기
                  </button>
                </td>
                <td>{dateChanger(item.date)}</td>
                <td>{item.readStatus ? 'O' : 'X'}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.pagingContainer}>
          <Paging
            page={intPage}
            countPerPage={10}
            pageRangeDisplayed={5}
            count={totalCount}
            setPage={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Alarm;
