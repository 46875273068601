import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './EventBanner.module.css';
import { getEventBanner } from '../../network/api/EventBannerAPI';
import Paging from '../util/Paging';
import ImagePopup from '../util/ImageDialog';
import EventBannerModifyDialog from './EventBannerModifyDialog';
import { deleteEventBanner } from '../../network/api/EventBannerAPI';
import EventBannerRegisterDialog from './EventBannerRegisterDialog';
import Select from 'react-select';

const categoryOptions = [
  { value: '전체', label: '전체' },
  { value: '상', label: '홈 상단배너' },
  { value: '중', label: '홈 띠배너' },
  { value: '하', label: '반려로운 소식 배너' },
  { value: 'qr', label: 'QR 배너' },
  { value: '내정보', label: '내정보 배너' },
];

const searchFilterOptions = [
  { value: 'desc', label: '최신순' },
  { value: 'asc', label: '오래된순' },
];

const EventBanner = () => {
  const navigate = useNavigate();
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [isModifyDialogOpen, setIsModifyDialogOpen] = useState(false);
  const [isRegisterDialogOpen, setIsRegisterDialogOpen] = useState(false);
  const initialOption = searchFilterOptions[0];
  const [selectedFilterOption, setSelectedFilterOption] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [eventBannerList, setEventBannerList] = useState([]);
  const [selectedEventBanner, setSelectedEventBanner] = useState(null);
  const [linkUrl, setLinkUrl] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageUrlList, setImageUrlLIst] = useState([]);
  const [eventBannerId, setEventBannerId] = useState(null);

  // 페이지 번호는 정수로 변환, 기본값 1
  const intPage = new URLSearchParams(location.search).get('page') || 1;

  useEffect(() => {
    // URL의 쿼리 파라미터를 읽어와서 상태를 초기화
    const queryParams = new URLSearchParams(location.search);

    const categoryOption = queryParams.get('categoryOption');
    const orderOption =
      queryParams.get('orderOption') || searchFilterOptions[0].value; // 기본값 설정

    // 페이지 번호는 정수로 변환, 기본값 1
    const intPage = parseInt(queryParams.get('page'), 10) || 1;

    // 상태 업데이트
    handleSetFilterOption(orderOption);

    handleSetCategoryFilterOption(categoryOption);

    // 페이지를 초기화하는 함수 호출
    getEventBannerInit(intPage, categoryOption, orderOption);
  }, [location.search]);

  const getEventBannerInit = async (page, categoryOption, orderOption) => {
    const param = {
      page: page, // intPage로 설정된 페이지가 아닌 인자로 넘겨받은 page 사용
      size: 10,
      ...(categoryOption && { categoryOption }), // categoryOption이 있으면 추가
      ...(orderOption && { orderOption }), // orderOption이 있으면 추가
    };
    const response = await getEventBanner(param);
    if (response) {
      setEventBannerList(response.content);
      setTotalCount(response.totalElements);
    }
  };

  const handleSetFilterOption = (searchOption) => {
    const foundOption = searchFilterOptions.find(
      (option) => option.value === searchOption
    );

    if (foundOption) {
      setSelectedFilterOption(foundOption);
    } else {
      setSelectedFilterOption(null);
    }
  };

  const handleSetCategoryFilterOption = (searchOption) => {
    const foundOption = categoryOptions.find(
      (option) => option.value === searchOption
    );

    if (foundOption) {
      setSelectedOption(foundOption);
    } else {
      setSelectedOption(null);
    }
  };

  const handleFilterOptionChange = (selectedOption) => {
    const newOrder = selectedOption ? selectedOption.value : null;

    // 현재 URL의 쿼리 파라미터를 가져옴
    const queryParams = new URLSearchParams(location.search);

    // page 파라미터를 1로 초기화
    queryParams.set('page', '1');

    // order 파라미터를 처리
    queryParams.set('orderOption', newOrder);

    // 새로운 URL로 이동
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const handleCategoryOptionChange = (selectedOption) => {
    const newOrder = selectedOption ? selectedOption.value : null;

    // 현재 URL의 쿼리 파라미터를 가져옴
    const queryParams = new URLSearchParams(location.search);

    // page 파라미터를 1로 초기화
    queryParams.set('page', '1');

    if (newOrder === '전체') {
      queryParams.delete('categoryOption');
    } else {
      // order 파라미터를 처리
      queryParams.set('categoryOption', newOrder);
    }

    // 새로운 URL로 이동
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const handlePageChange = async (page) => {
    // 기본 URL 설정
    let url = '/event-banner';

    // 현재 URL의 쿼리 파라미터를 가져옴
    const queryParams = new URLSearchParams(location.search);

    // page 파라미터를 업데이트
    if (page) {
      queryParams.set('page', page);
    }

    // URL에 쿼리 파라미터 추가
    if (queryParams.toString()) {
      url += `?${queryParams.toString()}`;
    }

    // 이동
    navigate(url);
  };

  const clickOpenDialog = (banner) => {
    const imageUrl = banner.imageUrl;
    const imageUrlList = banner.contentImages.map((item) => item.imageUrl);

    setImageUrl(imageUrl);
    setImageUrlLIst(imageUrlList);
    setIsImageDialogOpen(true);
  };

  const clickModifyDialog = (item) => {
    setSelectedEventBanner(item);
    setIsModifyDialogOpen(true);
  };

  const handleEventBannerDeleteButtonClick = (bannerId) => {
    const result = window.confirm('정말 삭제하시겠습니까?');

    if (result) {
      clickDeleteDialog(bannerId);
    } else {
      return;
    }
  };

  const clickDeleteDialog = async (bannerId) => {
    const response = await deleteEventBanner({ id: bannerId });
    if (response === 'success') {
      alert('삭제완료');
      window.location.reload(true);
    } else {
      alert('삭제실패');
    }
  };

  return (
    <div className={styles.wrapper}>
      {isRegisterDialogOpen ? (
        <EventBannerRegisterDialog
          setIsRegisterDialogOpen={setIsRegisterDialogOpen}
          intPage={intPage}
        />
      ) : null}
      {isModifyDialogOpen && selectedEventBanner !== null ? (
        <EventBannerModifyDialog
          eventBannerId={eventBannerId}
          setIsModifyDialogOpen={setIsModifyDialogOpen}
          eventBanner={selectedEventBanner}
        />
      ) : null}
      {isImageDialogOpen && imageUrl !== null ? (
        <ImagePopup
          setIsImageDialogOpen={setIsImageDialogOpen}
          imageUrl={imageUrl}
          imageUrlList={imageUrlList}
          type='image'
        />
      ) : null}
      <div className={styles.innerWrapper}>
        <div className={styles.titleText}>이벤트 배너 관리</div>
        <div className={styles.listContainer}>
          <div className={styles.totalCount}>전체 : {totalCount} 건</div>
          <div className={styles.filterBox}>
            <p>배너 위치 </p>
            <Select
              value={selectedOption}
              onChange={handleCategoryOptionChange}
              options={categoryOptions}
              placeholder='배너 위치 선택'
            />
          </div>
          <div className={styles.filterBox}>
            <p>정렬</p>
            <Select
              value={selectedFilterOption || initialOption}
              onChange={handleFilterOptionChange}
              options={searchFilterOptions}
              placeholder='선택'
            />
          </div>
          <div
            className={styles.button}
            onClick={() => {
              setIsRegisterDialogOpen(true);
            }}>
            추가하기
          </div>
        </div>
        <table className={styles.customTable}>
          <colgroup>
            <col style={{ minWidth: '120px' }}></col>
            <col style={{ minWidth: '90px' }}></col>
            <col style={{ minWidth: '90px' }}></col>
            <col style={{ minWidth: '90px' }}></col>
            <col style={{ minWidth: '90px' }}></col>
          </colgroup>
          <thead>
            <tr>
              <td>배너 위치</td>
              <td>배너 링크</td>
              <td>이미지</td>
              <td></td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {eventBannerList.length > 0 ? (
              eventBannerList.map((item, index) => (
                <tr key={index}>
                  <td>
                    {{
                      상: '홈 상단배너',
                      중: '홈 띠배너',
                      '반려로운 소식': '반려로운 소식 배너',
                      qr: 'QR 배너',
                      내정보: '내정보 배너',
                    }[item.position] || null}
                  </td>
                  <td>
                    <span
                      className={styles.linkStyle}
                      onClick={
                        item.linkUrl &&
                        (() => {
                          window.open(item.linkUrl, '_blank');
                        })
                      }>
                      {item.linkUrl ? item.linkUrl : '-'}
                    </span>
                  </td>
                  <td>
                    <button
                      className={styles.button}
                      onClick={() => {
                        clickOpenDialog(item);
                      }}>
                      이미지 보기
                    </button>
                  </td>
                  <td>
                    <button
                      className={styles.button}
                      onClick={() => {
                        clickModifyDialog(item);
                      }}>
                      수정
                    </button>
                  </td>
                  <td>
                    <button
                      className={styles.rbutton}
                      onClick={() =>
                        handleEventBannerDeleteButtonClick(item.id)
                      }>
                      삭제
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <div>등록된 배너가 없습니다</div>
            )}
          </tbody>
        </table>
        <div className={styles.pagingContainer}>
          <Paging
            page={parseInt(intPage, 10)}
            countPerPage={10}
            pageRangeDisplayed={5}
            count={totalCount}
            setPage={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default EventBanner;
