import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './VisitPlaceModifyDialog.module.css';
import PropTypes from 'prop-types';
import altImage from '../../resource/images/alt_image.png';
import { registerVisitPlace } from '../../network/api/VisitPlaceAPI';
import TextareaAutosize from 'react-textarea-autosize';
import CloseButton from '../../resource/images/svg/CloseButton';
import { compressImage } from '../../utils/compressImage';

let imageFileBlob = null;

const VisitPlaceRegisterDialog = ({
  setIsRegisterVisitPlaceDialogOpen,
  category,
  intPage,
}) => {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [linkUrl, setLinkLink] = useState('');
  const [image, setImage] = useState(null);
  const [location, setLocation] = useState('');
  const [inputNotice, setInputNotice] = useState('');
  const [outputNotice, setOutputNotice] = useState('');
  const [noticeTextCount, setNoticeTextCount] = useState(0);

  useEffect(() => {
    return () => {
      imageFileBlob = null;
    };
  }, []);

  const locationInputHandler = (e) => {
    setLocation(e.target.value);
  };

  const noticeInputHandler = (e) => {
    const currentNewlines = (e.target.value.match(/\n/g) || []).length;

    if (currentNewlines < 2) {
      setNoticeTextCount(
        e.target.value.substring(0, 500).replace(/<br\s*\/?>/gm, '\n').length
      );
      const newText = e.target.value;
      setInputNotice(newText.substring(0, 500));

      const paragraphs = newText.split('\n').map((line) => `<p>${line}</p>`);
      const resultString = paragraphs.join('');
      setOutputNotice(resultString);
    }
  };

  const linkUrlInputHandler = (e) => {
    setLinkLink(e.target.value);
  };

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        alert('파일 크기는 2MB 이하여야 합니다.');
      } else {
        const compressedImage = await compressImage(file);
        const fileURL = URL.createObjectURL(compressedImage);
        imageFileBlob = compressedImage;
        setImage(fileURL);
      }
    }
    event.target.value = '';
  };

  const openCamera = () => inputRef.current && inputRef.current.click();

  const registerButtonClick = async () => {
    if (
      linkUrl === '' ||
      image === null ||
      location === '' ||
      outputNotice === ''
    ) {
      alert('입력항목들을 모두 입력해 주세요');
    } else if (image !== null) {
      const formData = new FormData();
      formData.append('multipartFile', imageFileBlob);
      const uploaderString = JSON.stringify({
        category: category,
        location: location,
        notice: outputNotice,
        linkUrl: linkUrl,
      });
      formData.append(
        'visitPlace',
        new Blob([uploaderString], { type: 'application/json' })
      );

      const response = await registerVisitPlace(formData);
      if (response === 'success') {
        alert('등록완료');
        window.location.reload(true);
      } else {
        alert('등록실패');
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div
          className={styles.dialogCloseButton}
          onClick={() => {
            setIsRegisterVisitPlaceDialogOpen(false);
          }}>
          <CloseButton />
        </div>
        <div className={styles.dialogContainer}>
          <div className={styles.inputTopTitle}>
            추가할 카테고리 : {category}
          </div>
          <div className={styles.inputTitle}>
            위치 (한글 기준 17자, 공백은 0.5자 취급)
          </div>
          <input
            type='text'
            className={styles.inputId}
            placeholder='위치'
            onChange={locationInputHandler}
            value={location}
          />
          <div className={styles.inputTitle}>
            안내문구 ( 한줄당 한글 기준 14자, 꼭 두줄로 작성 )
          </div>
          <TextareaAutosize
            maxLength={10000}
            className={styles.quillBox}
            value={inputNotice}
            placeholder={'안내문구를 입력하세요'}
            onChange={noticeInputHandler}
          />
          <div>{noticeTextCount}/500</div>
          <div className={styles.inputTitle}>링크</div>
          <input
            type='text'
            className={styles.inputId}
            placeholder='링크'
            onChange={linkUrlInputHandler}
            value={linkUrl}
          />
          <div className={styles.inputTitle}>이미지 (최대 2MB)</div>
          <div className={styles.imageContainer}>
            {image !== null && image !== '' ? (
              <>
                <input
                  id='input-store-image-upload'
                  type='file'
                  ref={inputRef}
                  accept='.jpg, .jpeg, .png'
                  onChange={handleFileInputChange}
                  style={{
                    position: 'absolute',
                    left: '-1000px',
                    visibility: 'hidden',
                  }}
                />
                <img
                  className={styles.afterImageContainer}
                  onClick={openCamera}
                  src={image}
                />
              </>
            ) : (
              <>
                <input
                  id='input-store-image-upload'
                  type='file'
                  ref={inputRef}
                  accept='.jpg, .jpeg, .png'
                  onChange={handleFileInputChange}
                  style={{
                    position: 'absolute',
                    left: '-1000px',
                    visibility: 'hidden',
                  }}
                />
                <label
                  htmlFor='input-store-image-upload'
                  className={styles.imageUploadButton}>
                  <img className={styles.profileBox} src={altImage} />
                </label>
              </>
            )}
          </div>
          <div
            className={styles.loginButton}
            onClick={() => {
              registerButtonClick();
            }}>
            추가하기
          </div>
        </div>
      </div>
    </div>
  );
};

VisitPlaceRegisterDialog.propTypes = {
  setIsRegisterVisitPlaceDialogOpen: PropTypes.func,
  category: PropTypes.string,
  intPage: PropTypes.number,
};

export default VisitPlaceRegisterDialog;
