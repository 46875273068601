import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import {
  getRestRoom,
  getRestRoomByName,
  getRestRoomByCategory,
  getRestRoomByNameCategory,
  deletePlace,
} from '../../network/api/PlaceAPI';
import styles from './Poo.module.css';
import ImageDialog from '../util/ImageDialog';
import Paging from '../util/Paging';
import PooRegisterDialog from './PooRegisterDialog';
import PooModifyDialog from './PooModifyDialog';
import { dateChanger } from '../../utils/converter';

const Poo = () => {
  const navigate = useNavigate();
  const [categoryList, setCategoryList] = useState([
    {
      category: '화장실',
      isSelected: false,
    },
    {
      category: '휴지통',
      isSelected: false,
    },
  ]);
  const [category, setCategory] = useState('');
  const [pooList, setPooList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [imageUrl, setImageUrl] = useState(null);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [poo, setPoo] = useState(null);
  const [isModifyDialogOpen, setIsModifyDialogOpen] = useState(false);
  const [isRegisterPooDialogOpen, setIsRegisterPooDialogOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const { page } = useParams();
  let intPage = parseInt(page);

  useEffect(() => {
    getPooPlaceInit();
  }, [intPage]);

  const getPooPlaceInit = async () => {
    if (allItemsUnselected()) {
      if (searchValue !== '') {
        const response = await getRestRoomByName({
          page: intPage,
          size: 10,
          name: searchValue,
        });
        if (response) {
          if (response === undefined) {
            return;
          }
          try {
            setPooList(response.content);
            setTotalCount(response.totalElements);
          } catch (error) {
            console.error('데이터 불러오기 실패:', error);
          }
        }
      } else {
        const response = await getRestRoom({
          page: intPage,
          size: 10,
        });
        if (response) {
          if (response === undefined) {
            return;
          }
          try {
            setPooList(response.content);
            setTotalCount(response.totalElements);
          } catch (error) {
            console.error('데이터 불러오기 실패:', error);
          }
        }
      }
    }
  };

  const allItemsUnselected = () => {
    return categoryList.every((item) => !item.isSelected);
  };

  const searchInputHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchClick();
    }
  };

  const searchClick = async () => {
    intPage = 1;
    if (searchValue !== '') {
      const response = await getRestRoomByName({
        page: intPage,
        size: 10,
        name: searchValue,
      });
      if (response) {
        if (response === undefined) {
          return;
        }
        try {
          setPooList(response.content);
          setTotalCount(response.totalElements);
        } catch (error) {
          console.error('데이터 불러오기 실패:', error);
        }
      }
    } else {
      getPooPlaceInit();
    }
  };

  const clickOpenDialog = (imageUrl) => {
    setImageUrl(imageUrl);
    setIsImageDialogOpen(true);
  };

  const clickDeleteDialog = (id) => {
    const result = window.confirm('정말 삭제하시겠습니까?');

    if (result) {
      deletePlaceFunc(id);
    } else {
      return;
    }
  };

  const deletePlaceFunc = async (id) => {
    const response = await deletePlace({ id: id });
    if (response === 'success') {
      alert('삭제완료');
      window.location.reload(true);
    } else {
      alert('삭제실패');
    }
  };

  const handlePageChange = async (page) => {
    navigate(`/event-schedule/${page}`, { state: category });
  };

  const clickModifyDialog = (poo) => {
    setPoo(poo);
    setIsModifyDialogOpen(true);
  };

  return (
    <div className={styles.wrapper}>
      {isImageDialogOpen && imageUrl !== null ? (
        <ImageDialog
          setIsImageDialogOpen={setIsImageDialogOpen}
          imageUrl={imageUrl}
          type='image'
        />
      ) : null}
      {isRegisterPooDialogOpen ? (
        <PooRegisterDialog
          setIsRegisterPooDialogOpen={setIsRegisterPooDialogOpen}
        />
      ) : null}
      {isModifyDialogOpen ? (
        <PooModifyDialog
          poo={poo}
          setIsModifyDialogOpen={setIsModifyDialogOpen}
        />
      ) : null}
      <div className={styles.innerWrapper}>
        <div className={styles.titleText}>화장실 / 휴지통 관리</div>
        <div className={styles.filterSection}>
          <input
            type='text'
            className={styles.searchBox}
            placeholder='장소명 입력'
            onChange={searchInputHandler}
            onKeyDown={handleKeyDown}
            value={searchValue}
          />
          <div
            className={styles.searchButton}
            onClick={() => {
              searchClick();
            }}>
            검색
          </div>
        </div>
        <div className={styles.listContainer}>
          <div className={styles.totalCount}>전체 : {totalCount} 건</div>
          <dis className={styles.emptyFlex} />
          <div
            className={styles.button}
            onClick={() => {
              setIsRegisterPooDialogOpen(true);
            }}>
            추가하기
          </div>
        </div>
        <table className={styles.customTable}>
          <colgroup>
            <col style={{ minWidth: '120px' }}></col>
            <col style={{ minWidth: '170px' }}></col>
            <col style={{ minWidth: '170px' }}></col>
            <col style={{ minWidth: '200px' }}></col>
            <col style={{ minWidth: '110px' }}></col>
            <col style={{ minWidth: '110px' }}></col>
          </colgroup>
          <thead>
            <tr>
              <td>카테고리</td>
              <td>장소이름</td>
              <td>장소 이미지</td>
              <td>등록일</td>
              <td></td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {pooList.map((item, index) => (
              <tr key={index}>
                <td>{item.category}</td>
                <td>{item.name}</td>
                <td>
                  <button
                    className={styles.button}
                    onClick={() => {
                      clickOpenDialog(item.imageUrlList[0]);
                    }}>
                    이미지 보기
                  </button>
                </td>
                <td>{dateChanger(item.registerDate)}</td>
                <td>
                  <button
                    className={styles.button}
                    onClick={() => {
                      clickModifyDialog(item);
                    }}>
                    수정
                  </button>
                </td>
                <td>
                  <button
                    className={styles.rbutton}
                    onClick={() => clickDeleteDialog(item.id)}>
                    삭제
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.pagingContainer}>
          <Paging
            page={intPage}
            countPerPage={10}
            pageRangeDisplayed={5}
            count={totalCount}
            setPage={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Poo;
