import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  getNoticeList,
  deleteNotice,
  getNoticeMain,
} from '../../network/api/NoticeAPI';
import Paging from '../util/Paging';
import styles from './Notice.module.css';
import NoticeRegisterDialog from './NoticeRegisterDialog';
import NoticeModifyDialog from './NoticeModifyDialog';
import { dateChanger } from '../../utils/converter';
import NoticeModal from './NoticeModal';

const Notice = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let previousState = location.state;
  const [totalCount, setTotalCount] = useState(0);
  const [noticeList, setNoticeList] = useState([]);
  const [notice, setNotice] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMain, setIsMain] = useState(false);

  const { page } = useParams();
  let intPage = parseInt(page);

  useEffect(() => {
    getNoticeListInit(previousState);
  }, [intPage]);

  const getNoticeListInit = async (previousStateProps) => {
    switch (previousStateProps) {
      case true: {
        const response = await getNoticeMain({
          page: 1,
          size: 10,
        });
        if (response) {
          setIsMain(true);
          setNoticeList(response.content);
          setTotalCount(response.totalElements);
        }
        break;
      }
      case null: {
        const response = await getNoticeList({
          page: intPage,
          size: 10,
        });
        if (response) {
          setIsMain(false);
          setNoticeList(response.content);
          setTotalCount(response.totalElements);
        }
        break;
      }
      default: {
        const response = await getNoticeList({
          page: intPage,
          size: 10,
        });
        if (response) {
          setIsMain(false);
          setNoticeList(response.content);
          setTotalCount(response.totalElements);
        }
      }
    }
  };

  const handlePageChange = async (page) => {
    navigate(`/notice/${page}`);
  };

  const searchClick = async () => {
    if (isMain) {
      if (intPage === 1) {
        getNoticeListInit(null);
      } else {
        navigate(`/notice/1`, { state: null });
      }
    } else {
      if (intPage === 1) {
        getNoticeListInit(true);
      } else {
        navigate(`/notice/${1}`, { state: true });
      }
    }
  };

  const handleNoticeDeleteButtonClick = (id) => {
    const result = window.confirm('정말 삭제하시겠습니까?');

    if (result) {
      deleteNoticeFunc(id);
    } else {
      return;
    }
  };

  const deleteNoticeFunc = async (id) => {
    const response = await deleteNotice({ id: id });
    if (response === 'success') {
      alert('삭제완료');
      window.location.reload(true);
    } else {
      alert('삭제실패');
    }
  };

  return (
    <div className={styles.wrapper}>
      {isModalOpen && (
        <NoticeModal
          notice={notice}
          setIsModalOpen={setIsModalOpen}
          setNotice={setNotice}
        />
      )}
      <div className={styles.innerWrapper}>
        <div className={styles.titleText}>공지 관리</div>
        <div className={styles.aboveContainer}>
          <div
            className={isMain ? styles.searchButtonSelect : styles.searchButton}
            onClick={() => {
              searchClick();
            }}>
            홈화면 공지
          </div>
          <div
            className={styles.addButton}
            onClick={() => {
              setIsModalOpen(true);
              setNotice(null);
            }}>
            추가하기
          </div>
        </div>
        <div className={styles.listContainer}>
          <div className={styles.totalCount}>전체 : {totalCount} 건</div>
        </div>
        <table className={styles.customTable}>
          <colgroup>
            <col style={{ minWidth: '200px' }}></col>
            <col style={{ minWidth: '170px' }}></col>
            <col style={{ minWidth: '80px' }}></col>
            <col style={{ minWidth: '110px' }}></col>
            <col style={{ minWidth: '110px' }}></col>
          </colgroup>
          <thead>
            <tr>
              <td>제목</td>
              <td>등록일</td>
              <td>홈화면 등록 여부</td>
              <td></td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {noticeList.map((item, index) => (
              <tr key={index}>
                <td title={item.title}>{item.title}</td>
                <td>{dateChanger(item.date)}</td>
                <td>{item.main ? 'O' : 'X'}</td>
                <td>
                  <button
                    className={styles.button}
                    onClick={() => {
                      setIsModalOpen(true);
                      setNotice(item);
                    }}>
                    수정
                  </button>
                </td>
                <td>
                  <button
                    className={styles.rbutton}
                    onClick={() => handleNoticeDeleteButtonClick(item.id)}>
                    삭제
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.pagingContainer}>
          <Paging
            page={intPage}
            countPerPage={10}
            pageRangeDisplayed={5}
            count={totalCount}
            setPage={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Notice;
