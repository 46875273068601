import { baseInstance, authInstance } from '../AxiosClient';

export const checkAdmin = async (adminInfo) => {
  try {
    const { data } = await authInstance.post(`admin/check`, adminInfo);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const changePassword = async (adminInfo) => {
  try {
    const { data } = await authInstance.post(`admin/change`, adminInfo);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};
