export const dateChanger = (date) => {
  if (date !== undefined && date !== null) {
    const dateObject = new Date(date);

    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    const hours = dateObject.getHours().toString().padStart(2, '0');
    const minutes = dateObject.getMinutes().toString().padStart(2, '0');
    const seconds = dateObject.getSeconds().toString().padStart(2, '0');
    return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
  } else {
    return '-';
  }
};

export const dateStringChanger = (isoString) => {
  const date = new Date(isoString);
  return date.toISOString().split('T')[0];
};
