import React, { useEffect, useState } from 'react';
import styles from './ImageDialog.module.css';
import PropTypes from 'prop-types';
import CloseButton from '../../resource/images/svg/CloseButton';

const ImageDialog = ({
  setIsImageDialogOpen,
  imageUrl,
  imageUrlList,
  type,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div
          className={styles.dialogCloseButton}
          onClick={() => {
            setIsImageDialogOpen(false);
          }}>
          <CloseButton />
        </div>
        <div className={styles.dialogContainer}>
          {/* 이미지 렌더링 */}
          {type === 'image' && imageUrlList !== undefined && <p>베너 이미지</p>}
          {type === 'image' && (
            <img
              src={imageUrl}
              alt='미리보기 이미지'
              style={{ width: '600px' }}
            />
          )}
          {type === 'image' &&
            imageUrlList !== undefined &&
            imageUrlList.length > 0 && <p>상세 이미지</p>}
          {imageUrlList !== undefined &&
            imageUrlList.map((resource, index) => (
              <img key={index} className={styles.image} src={resource} />
            ))}

          {/* 동영상 렌더링 */}
          {type === 'video' && (
            <video width='600' controls>
              <source src={imageUrl} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      </div>
    </div>
  );
};

ImageDialog.propTypes = {
  setIsImageDialogOpen: PropTypes.func,
  imageUrl: PropTypes.string,
  imageUrlList: PropTypes.array,
  type: PropTypes.string,
};

export default ImageDialog;
