import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Paging from '../util/Paging';
import Select from 'react-select';
import styles from './Dog.module.css';
import {
  deleteDog,
  downloadDogExcel,
  getDogList,
  permitDogStatus,
  rejectDogStatus,
} from '../../network/api/DogAPI';
import { dateChanger, dateStringChanger } from '../../utils/converter';

const searchOptions = [
  { value: 'username', label: '소유자 아이디' },
  { value: 'name', label: '이름' },
  { value: 'ownername', label: '소유자명' },
  { value: 'number', label: '등록번호' },
];

const searchFilterOptions = [
  { value: 'desc', label: '최신순' },
  { value: 'asc', label: '오래된순' },
];

const Dog = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);
  const initialOption = searchFilterOptions[0];
  const [selectedFilterOption, setSelectedFilterOption] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [dogList, setDogList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  // 페이지 번호는 정수로 변환, 기본값 1
  const intPage = new URLSearchParams(location.search).get('page') || 1;

  useEffect(() => {
    // URL의 쿼리 파라미터를 읽어와서 상태를 초기화
    const queryParams = new URLSearchParams(location.search);

    const search = queryParams.get('search') || '';
    const searchOption = queryParams.get('searchOption');
    const orderOption =
      queryParams.get('orderOption') || searchFilterOptions[0].value; // 기본값 설정

    // 페이지 번호는 정수로 변환, 기본값 1
    const intPage = parseInt(queryParams.get('page'), 10) || 1;

    // 상태 업데이트
    setSearchValue(search);
    setSelectedOption(
      searchOption
        ? {
            value: searchOption,
            label:
              {
                username: '소유자 아이디',
                name: '이름',
                ownername: '소유자명',
                number: '등록번호',
              }[searchOption] || '',
          }
        : null
    );
    handleSetFilterOption(orderOption);

    // 페이지를 초기화하는 함수 호출
    getDogInit(intPage, search, searchOption, orderOption);
  }, [location.search]);

  const getDogInit = async (page, search, searchOption, orderOption) => {
    const param = {
      page: page, // intPage로 설정된 페이지가 아닌 인자로 넘겨받은 page 사용
      size: 10,
      ...(searchOption && { [searchOption]: search }), // searchOption에 따라 조건 추가
      ...(orderOption && { orderOption }), // orderOption이 있으면 추가
    };

    const response = await getDogList(param);
    if (response) {
      setDogList(response.content);
      setTotalCount(response.totalElements);
      setIsLoaded(true);
    }
  };

  const handleSetFilterOption = (searchOption) => {
    const foundOption = searchFilterOptions.find(
      (option) => option.value === searchOption
    );

    if (foundOption) {
      setSelectedFilterOption(foundOption);
    } else {
      setSelectedFilterOption(null);
    }
  };

  const handlePageChange = async (page) => {
    // 기본 URL 설정
    let url = '/dog';

    // 현재 URL의 쿼리 파라미터를 가져옴
    const queryParams = new URLSearchParams(location.search);

    // page 파라미터를 업데이트
    if (page) {
      queryParams.set('page', page);
    }

    // URL에 쿼리 파라미터 추가
    if (queryParams.toString()) {
      url += `?${queryParams.toString()}`;
    }

    // 이동
    navigate(url);
  };

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const searchInputHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchClick();
    }
  };

  const handleFilterOptionChange = (selectedOption) => {
    const newOrder = selectedOption ? selectedOption.value : null;

    // 상태 업데이트
    setSelectedFilterOption(selectedOption);

    // 현재 URL의 쿼리 파라미터를 가져옴
    const queryParams = new URLSearchParams(location.search);

    // page 파라미터를 1로 초기화
    queryParams.set('page', '1');

    // order 파라미터를 처리
    queryParams.set('orderOption', newOrder);

    // 새로운 URL로 이동
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const searchClick = async () => {
    if (selectedOption !== null) {
      if (searchValue !== '') {
        if (selectedOption.value === 'number') {
          if (!/^\d*$/.test(searchValue)) {
            alert('반려동물 등록번호는 숫자만 입력해주세요');
            setSearchValue('');
            return;
          }
        }

        const queryParams = new URLSearchParams(location.search);
        queryParams.set('page', '1');
        if (searchValue) {
          queryParams.set('searchOption', selectedOption.value);
          queryParams.set('search', searchValue);
        } else {
          queryParams.delete('search');
          queryParams.delete('searchOption');
        }

        navigate(`${location.pathname}?${queryParams.toString()}`);
      } else {
        alert('검색할 내용을 입력해주세요');
      }
    } else {
      alert('검색할 옵션을 선택해주세요');
    }
  };

  const resetOption = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('page', '1');
    queryParams.delete('search');
    queryParams.delete('searchOption');
    queryParams.delete('orderOption');
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const permitButtonClick = async (status, id) => {
    if (status !== '승인') {
      const response = await permitDogStatus({ id: id });

      if (response === 'success') {
        window.location.reload(true);
      } else {
        alert('승인실패');
      }
    }
  };

  const rejectButtonClick = async (status, id) => {
    if (status !== '반려') {
      const response = await rejectDogStatus({ id: id });

      if (response === 'success') {
        window.location.reload(true);
      } else {
        alert('반려실패');
      }
    }
  };

  const deleteButtonClick = (id) => {
    const result = window.confirm('정말 삭제하시겠습니까?');

    if (result) {
      deleteDogFunc(id);
    } else {
      return;
    }
  };

  const deleteDogFunc = async (id) => {
    const response = await deleteDog(id);
    if (response === 'success') {
      alert('삭제완료');
      window.location.reload(true);
    } else {
      alert('삭제실패');
    }
  };

  const downloadExcel = async () => {
    const queryParams = new URLSearchParams(location.search);

    const search = queryParams.get('search') || '';
    const searchOption = queryParams.get('searchOption');

    const param = {
      ...(searchOption && { [searchOption]: search }), // searchOption에 따라 조건 추가
    };

    const response = await downloadDogExcel(param);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div className={styles.titleText}>반려동물 관리</div>
        <div className={styles.filterSection}>
          <Select
            value={selectedOption}
            onChange={handleOptionChange}
            options={searchOptions}
            placeholder='선택'
          />
          <input
            type='text'
            className={styles.searchBox}
            placeholder='입력'
            onChange={searchInputHandler}
            onKeyDown={handleKeyDown}
            value={searchValue}
          />
          <div
            className={styles.searchButton}
            onClick={() => {
              searchClick();
            }}>
            검색
          </div>
          <div
            className={styles.searchButton}
            onClick={() => {
              resetOption();
            }}>
            초기화
          </div>
          <div
            className={styles.excelButton}
            onClick={() => {
              downloadExcel();
            }}>
            엑셀 다운로드
          </div>
        </div>
        <div className={styles.listContainer}>
          <div className={styles.totalCount}>전체 : {totalCount} 건</div>
          <div className={styles.filterBox}>
            <p>정렬</p>
            <Select
              value={selectedFilterOption || initialOption}
              onChange={handleFilterOptionChange}
              options={searchFilterOptions}
              placeholder='선택'
            />
          </div>
        </div>
        <table className={styles.customTable}>
          <colgroup>
            <col style={{ minWidth: '120px' }}></col>
            <col style={{ minWidth: '120px' }}></col>
            <col style={{ minWidth: '120px' }}></col>
            <col style={{ minWidth: '150px' }}></col>
            <col style={{ minWidth: '150px' }}></col>
            <col style={{ minWidth: '150px' }}></col>
            <col style={{ minWidth: '80px' }}></col>
            <col style={{ minWidth: '80px' }}></col>
            <col style={{ minWidth: '80px' }}></col>
            <col style={{ minWidth: '180px' }}></col>
            <col style={{ minWidth: '120px' }}></col>
            <col style={{ minWidth: '150px' }}></col>
            <col style={{ minWidth: '100px' }}></col>
            <col style={{ minWidth: '180px' }}></col>
            <col style={{ minWidth: '180px' }}></col>
            <col style={{ minWidth: '180px' }}></col>
            <col style={{ minWidth: '110px' }}></col>
            <col style={{ minWidth: '200px' }}></col>
          </colgroup>
          <thead>
            <tr>
              <th>유저 닉네임</th>
              <th>메인 여부</th>
              <th>반려동물</th>
              <th>이름</th>
              <th>견종</th>
              <th>생일</th>
              <th>몸무게</th>
              <th>성별</th>
              <th>중성화 여부</th>
              <th>반려동물 등록번호</th>
              <th>소유자명</th>
              <th>소유자 아이디</th>
              <th>승인상태</th>
              <th>등록일</th>
              <th>승인일</th>
              <th>반려일</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {isLoaded
              ? dogList.map((item, index) => (
                  <tr key={index}>
                    <td
                      className={styles.linkStyle}
                      onClick={() => {
                        navigate(
                          `/user?page=1&searchOption=nickname&search=${item.nickname}`
                        );
                      }}>
                      {item.nickname}
                    </td>
                    <td>{item.isMain ? 'O' : 'X'}</td>
                    <td>{item.species === 'dog' ? '강아지' : item.species}</td>
                    <td>{item.name}</td>
                    <td>{item.breed}</td>
                    <td>{dateStringChanger(item.birthday)}</td>
                    <td>{item.weight}kg</td>
                    <td>{item.gender === 'female' ? '여' : '남'}</td>
                    <td>{item.neutered ? 'O' : 'X'}</td>
                    <td>
                      {item.animalRegistrationNumber !== ''
                        ? item.animalRegistrationNumber
                        : '미등록'}
                    </td>
                    <td>{item.ownerName !== '' ? item.ownerName : '미등록'}</td>
                    <td>{item.result !== '' ? item.result : '-'}</td>
                    <td>{item.status}</td>
                    <td>{dateChanger(item.registerDate)}</td>
                    <td>{dateChanger(item.permitDate)}</td>
                    <td>{dateChanger(item.rejectDate)}</td>
                    <td
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}>
                      <button
                        className={styles.button}
                        onClick={() => {
                          permitButtonClick(item.status, item.id);
                        }}>
                        승인
                      </button>
                      <button
                        className={styles.rbutton}
                        onClick={() => {
                          rejectButtonClick(item.status, item.id);
                        }}>
                        반려
                      </button>
                      <button
                        className={styles.rbutton}
                        onClick={() => {
                          deleteButtonClick(item.id);
                        }}>
                        삭제
                      </button>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
        <div className={styles.pagingContainer}>
          <Paging
            page={parseInt(intPage, 10)}
            countPerPage={10}
            pageRangeDisplayed={5}
            count={totalCount}
            setPage={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Dog;
