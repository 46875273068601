import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Paging from '../util/Paging';
import styles from './User.module.css';
import { getUserList, getUserListWithSearch } from '../../network/api/UserAPI';
import ImageDialog from '../util/ImageDialog';
import UserDogDialog from './UserDogDialog';
import Select from 'react-select';
import { dateChanger } from '../../utils/converter';

const searchOptions = [
  { value: 'username', label: '아이디' },
  { value: 'name', label: '이름' },
  { value: 'nickname', label: '닉네임' },
  { value: 'phone', label: '휴대폰' },
];

const searchFilterOptions = [
  { value: 'desc', label: '최신순' },
  { value: 'asc', label: '오래된순' },
];

const User = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const initialOption = searchFilterOptions[0];
  const [selectedFilterOption, setSelectedFilterOption] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [userList, setUserList] = useState([]);
  const [image, setImage] = useState(null);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  // 페이지 번호는 정수로 변환, 기본값 1
  const intPage = new URLSearchParams(location.search).get('page') || 1;

  useEffect(() => {
    // URL의 쿼리 파라미터를 읽어와서 상태를 초기화
    const queryParams = new URLSearchParams(location.search);

    const search = queryParams.get('search') || '';
    const searchOption = queryParams.get('searchOption');
    const orderOption =
      queryParams.get('orderOption') || searchFilterOptions[0].value; // 기본값 설정

    // 페이지 번호는 정수로 변환, 기본값 1
    const intPage = parseInt(queryParams.get('page'), 10) || 1;

    // 상태 업데이트
    setSearchValue(search);
    setSelectedOption(
      searchOption
        ? {
            value: searchOption,
            label:
              {
                username: '아이디',
                name: '이름',
                nickname: '닉네임',
                phone: '휴대폰',
              }[searchOption] || '',
          }
        : null
    );
    handleSetFilterOption(orderOption);

    // 페이지를 초기화하는 함수 호출
    getUserListInit(intPage, search, searchOption, orderOption);
  }, [location.search]);

  const getUserListInit = async (page, search, searchOption, orderOption) => {
    const param = {
      page: page, // intPage로 설정된 페이지가 아닌 인자로 넘겨받은 page 사용
      size: 10,
      ...(searchOption && { [searchOption]: search }), // searchOption에 따라 조건 추가
      ...(orderOption && { orderOption }), // orderOption이 있으면 추가
    };

    const response = await getUserList(param);
    if (response) {
      setUserList(response.content);
      setTotalCount(response.totalElements);
    }
  };

  const handleSetFilterOption = (searchOption) => {
    const foundOption = searchFilterOptions.find(
      (option) => option.value === searchOption
    );

    if (foundOption) {
      setSelectedFilterOption(foundOption);
    } else {
      setSelectedFilterOption(null);
    }
  };

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const searchInputHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchClick(false);
    }
  };

  const handlePageChange = async (page) => {
    // 기본 URL 설정
    let url = '/user';

    // 현재 URL의 쿼리 파라미터를 가져옴
    const queryParams = new URLSearchParams(location.search);

    // page 파라미터를 업데이트
    if (page) {
      queryParams.set('page', page);
    }

    // URL에 쿼리 파라미터 추가
    if (queryParams.toString()) {
      url += `?${queryParams.toString()}`;
    }

    // 이동
    navigate(url);
  };

  const clickOpenDialog = (image) => {
    if (image !== null && image !== undefined && image !== '') {
      setImage(image);
      setIsImageDialogOpen(true);
    } else {
      alert('등록된 이미지가 없습니다');
    }
  };

  const handleFilterOptionChange = (selectedOption) => {
    const newOrder = selectedOption ? selectedOption.value : null;

    // 현재 URL의 쿼리 파라미터를 가져옴
    const queryParams = new URLSearchParams(location.search);

    // page 파라미터를 1로 초기화
    queryParams.set('page', '1');

    // order 파라미터를 처리
    queryParams.set('orderOption', newOrder);

    // 새로운 URL로 이동
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const searchClick = async () => {
    if (selectedOption !== null) {
      if (searchValue !== '') {
        if (selectedOption.value === 'phone') {
          if (!/^\d*$/.test(searchValue)) {
            alert('휴대폰번호는 숫자만 입력해주세요');
            setSearchValue('');
            return;
          }
        }

        const queryParams = new URLSearchParams(location.search);
        queryParams.set('page', '1');
        if (searchValue) {
          queryParams.set('searchOption', selectedOption.value);
          queryParams.set('search', searchValue);
        } else {
          queryParams.delete('search');
          queryParams.delete('searchOption');
        }

        navigate(`${location.pathname}?${queryParams.toString()}`);
      } else {
        alert('검색할 내용을 입력해주세요');
      }
    } else {
      alert('검색할 옵션을 선택해주세요');
    }
  };

  const resetOption = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('page', '1');
    queryParams.delete('search');
    queryParams.delete('searchOption');
    queryParams.delete('orderOption');
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  return (
    <div className={styles.wrapper}>
      {isImageDialogOpen && image !== null ? (
        <ImageDialog
          setIsImageDialogOpen={setIsImageDialogOpen}
          imageUrl={image}
          type='image'
        />
      ) : null}
      <div className={styles.innerWrapper}>
        <div className={styles.titleText}>유저관리</div>
        <div className={styles.filterSection}>
          <Select
            value={selectedOption}
            onChange={handleOptionChange}
            options={searchOptions}
            placeholder='선택'
          />
          <input
            type='text'
            className={styles.searchBox}
            placeholder='입력'
            onChange={searchInputHandler}
            onKeyDown={handleKeyDown}
            value={searchValue}
          />
          <div
            className={styles.searchButton}
            onClick={() => {
              searchClick(false);
            }}>
            검색
          </div>
          <div
            className={styles.searchButton}
            onClick={() => {
              resetOption();
            }}>
            초기화
          </div>
        </div>
        <div className={styles.listContainer}>
          <div className={styles.totalCount}>전체 : {totalCount} 건</div>
          <div className={styles.filterBox}>
            <p>정렬</p>
            <Select
              value={selectedFilterOption || initialOption}
              onChange={handleFilterOptionChange}
              options={searchFilterOptions}
              placeholder='선택'
            />
          </div>
        </div>
        <table className={styles.customTable}>
          <colgroup>
            <col style={{ minWidth: '90px' }}></col>
            <col style={{ minWidth: '120px' }}></col>
            <col style={{ minWidth: '150px' }}></col>
            <col style={{ minWidth: '120px' }}></col>
            <col style={{ minWidth: '100px' }}></col>
            <col style={{ minWidth: '180px' }}></col>
            <col style={{ minWidth: '200px' }}></col>
            <col style={{ minWidth: '200px' }}></col>
            <col style={{ minWidth: '70px' }}></col>
            <col style={{ minWidth: '180px' }}></col>
          </colgroup>
          <thead>
            <tr>
              <th>ID</th>
              <th>닉네임</th>
              <th>이메일(아이디)</th>
              <th>이름</th>
              <th>휴대폰</th>
              <th>성별</th>
              <th>생년월일</th>
              <th>프로필 이미지</th>
              <th>가입일</th>
              <th>최근 로그인 일자</th>
              <th>반려동물 등록 여부</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {userList.map((item, index) => (
              <tr key={index}>
                <td>{item.id}</td>
                <td>{item.nickname}</td>
                <td>{item.username}</td>
                <td>{item.name}</td>
                <td>{item.phone}</td>
                <td>
                  {item.gender === 'male'
                    ? '남자'
                    : item.gender === 'female'
                    ? '여자'
                    : '-'}
                </td>
                <td>{item.birthday}</td>
                <td>
                  <button
                    className={styles.button}
                    onClick={() => {
                      clickOpenDialog(item.profileUrl);
                    }}>
                    이미지 보기
                  </button>
                </td>
                <td>{dateChanger(item.joinDate)}</td>
                <td>{dateChanger(item.loginDate)}</td>
                <td>{item.dogList.length === 0 ? 'X' : 'O'}</td>
                {item.dogList.length === 0 ? (
                  <td className={styles.emptyButton} />
                ) : (
                  <td>
                    <button
                      className={styles.button}
                      onClick={() => {
                        navigate(
                          `/dog?page=1&searchOption=username&search=${item.username}`
                        );
                      }}>
                      반려동물 확인
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.pagingContainer}>
          <Paging
            page={parseInt(intPage, 10)}
            countPerPage={10}
            pageRangeDisplayed={5}
            count={totalCount}
            setPage={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default User;
