import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import Paging from '../util/Paging';
// import styles from '../with-together/WithTogether.module.css';
import styles from './EventSchedule.module.css';
import {
  getEventSchedule,
  getEventScheduleByCategory,
  deleteEventSchedule,
  getEventScheduleSearch,
} from '../../network/api/EventScheduleAPI';

import ImageDialog from '../util/ImageDialog';
import EventScheduleModifyDialog from './EventScheduleModifyDialog';
import EventScheduleRegisterDialog from './EventScheduleRegisterDialog';

const EventSchedule = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [categoryList, setCategoryList] = useState([
    {
      category: '행사',
      isSelected: false,
    },
    {
      category: '교육',
      isSelected: false,
    },
    {
      category: '축제',
      isSelected: false,
    },
  ]);
  const [eventScheduleList, setEventScheduleList] = useState([]);
  const [eventSchedule, setEventSchedule] = useState(null);
  const [category, setCategory] = useState('');
  const [imageUrlList, setImageUrlList] = useState(null);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [isModifyDialogOpen, setIsModifyDialogOpen] = useState(false);
  const [
    isRegisterEventScheduleDialogOpen,
    setIsRegisterEventScheduleDialogOpen,
  ] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const { page } = useParams();
  let intPage = parseInt(page);

  useEffect(() => {
    getEventSchedulePlaceInit();
  }, [intPage, categoryList]);

  const getEventSchedulePlaceInit = async () => {
    if (searchValue !== '') {
      const response = await getEventScheduleSearch({
        input: searchValue,
        page: intPage,
        size: 10,
      });
      if (response) {
        if (response === undefined) {
          return;
        }
        try {
          setEventScheduleList(response.content);
          setTotalCount(response.totalElements);
        } catch (error) {
          console.error('데이터 불러오기 실패:', error);
        }
      }
    } else {
      if (allItemsUnselected()) {
        const response = await getEventSchedule({
          page: intPage,
          size: 10,
        });
        if (response) {
          if (response === undefined) {
            return;
          }
          try {
            setEventScheduleList(response.content);
            setTotalCount(response.totalElements);
          } catch (error) {
            console.error('데이터 불러오기 실패:', error);
          }
        }
      } else {
        let categoryList = checkCategory();
        const response = await getEventScheduleByCategory({
          page: intPage,
          size: 10,
          categoryList: categoryList.join(', '),
        });
        if (response) {
          if (response === undefined) {
            return;
          }
          try {
            setEventScheduleList(response.content);
            setTotalCount(response.totalElements);
          } catch (error) {
            console.error('데이터 불러오기 실패:', error);
          }
        }
      }
    }
  };

  const searchInputHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchClick();
    }
  };

  const searchClick = async () => {
    intPage = 1;
    getEventSchedulePlaceInit();
  };

  const allItemsUnselected = () => {
    return categoryList.every((item) => !item.isSelected);
  };

  const checkCategory = () => {
    let tempCategoryList = [];
    for (const item of categoryList) {
      if (item.isSelected) {
        tempCategoryList.push(item.category);
      }
    }
    return tempCategoryList;
  };

  const menu = categoryList.map((categoryItem, index) => (
    <div
      key={index}
      className={
        categoryItem.isSelected ? styles.slectedMenuItem : styles.menuItem
      }
      onClick={() => {
        handleToggleCategoryList(categoryItem);
      }}>
      {categoryItem.category}
    </div>
  ));

  const handleToggleCategoryList = (categoryItem) => {
    if (intPage === 1) {
      setCategoryList(
        categoryList.map((item) => {
          if (item.category === categoryItem.category) {
            return { ...item, isSelected: !item.isSelected };
          } else {
            return item;
          }
        })
      );
      intPage = 1;
      setCategory(categoryItem.category);
    } else {
      navigate(`/event-schedule/${1}`, { state: category });
    }
  };

  const handlePageChange = async (page) => {
    navigate(`/event-schedule/${page}`, { state: category });
  };

  const changeDateFormat = (dateString) => {
    if (dateString !== '' && dateString !== null) {
      const dateParts = dateString.split('-');
      const year = dateParts[0];
      const month = dateParts[1];
      const day = dateParts[2].split('T')[0];
      return `${year}년 ${month}월 ${day}일`;
    } else {
      return '-';
    }
  };

  const clickOpenDialog = (imageUrlList) => {
    setImageUrlList(imageUrlList);
    setIsImageDialogOpen(true);
  };

  const clickModifyDialog = (participatePlace) => {
    setEventSchedule(participatePlace);
    setIsModifyDialogOpen(true);
  };

  const handleEventScheduleDeleteButtonClick = (id) => {
    const result = window.confirm('정말 삭제하시겠습니까?');

    if (result) {
      deleteVisitPlaceFunc(id);
    } else {
      return;
    }
  };

  const deleteVisitPlaceFunc = async (id) => {
    const response = await deleteEventSchedule({ id: id });
    if (response === 'success') {
      alert('삭제완료');
      window.location.reload(true);
    } else {
      alert('삭제실패');
    }
  };

  const EventScheduleRow = ({ item }) => {
    const [isPriceExpanded, setIsPriceExpanded] = useState(false);
    const [isNoticeExpanded, setIsNoticeExpanded] = useState(false);
    const [isPriceClamped, setIsPriceClamped] = useState(false);
    const [isNoticeClamped, setIsNoticeClamped] = useState(false);

    const priceRef = useRef(null);
    const noticeRef = useRef(null);

    // 3줄이 넘는지 체크하는 함수
    const checkIfClamped = (ref, setClamped) => {
      if (ref.current) {
        const lineHeight = parseFloat(getComputedStyle(ref.current).lineHeight);
        const maxHeight = lineHeight * 3; // 3줄의 높이 계산
        if (ref.current.scrollHeight > maxHeight) {
          setClamped(true);
        } else {
          setClamped(false);
        }
      }
    };

    // DOM이 렌더된 후 높이를 계산하기 위해 useLayoutEffect 사용
    useLayoutEffect(() => {
      checkIfClamped(priceRef, setIsPriceClamped);
      checkIfClamped(noticeRef, setIsNoticeClamped);
    }, [item.priceInformation, item.notice]); // item이 업데이트될 때마다 다시 체크

    const togglePriceExpanded = () => setIsPriceExpanded(!isPriceExpanded);
    const toggleNoticeExpanded = () => setIsNoticeExpanded(!isNoticeExpanded);

    return (
      <tr>
        <td>{item.category}</td>
        <td>{item.name}</td>
        <td>{item.phone}</td>
        <td>
          <span
            className={styles.linkStyle}
            onClick={() => window.open(item.homepageUrl, '_blank')}>
            {item.homepageUrl}
          </span>
        </td>
        <td>{item.location}</td>
        <td>{item.detailLocation}</td>
        <td>{item.jibunAddress}</td>
        <td>{item.roadAddress}</td>
        <td>
          {changeDateFormat(item.startTime)} <br /> ~ <br />
          {changeDateFormat(item.finishTime)}
        </td>
        <td>
          {item.lowestPrice ? `${item.lowestPrice}원` : '-'}
          <br /> ~ <br />
          {item.maximumPrice ? `${item.maximumPrice}원` : '-'}
        </td>
        {/* 가격 정보 - 3줄 제한 적용 */}
        <td>
          <div
            className={isPriceExpanded ? '' : styles.clampText}
            dangerouslySetInnerHTML={{ __html: item.priceInformation }}
            ref={priceRef}></div>
          {!isPriceExpanded && isPriceClamped && (
            <button className={styles.readMore} onClick={togglePriceExpanded}>
              자세히
            </button>
          )}
        </td>
        {/* 안내문구 - 3줄 제한 적용 */}
        <td>
          <div
            className={isNoticeExpanded ? '' : styles.clampText}
            dangerouslySetInnerHTML={{ __html: item.notice }}
            ref={noticeRef}></div>
          {!isNoticeExpanded && isNoticeClamped && (
            <button className={styles.readMore} onClick={toggleNoticeExpanded}>
              자세히
            </button>
          )}
        </td>
        <td>
          <button
            className={styles.button}
            onClick={() => clickOpenDialog(item.imageUrlList)}>
            이미지 보기
          </button>
        </td>
        <td>
          <button
            className={styles.button}
            onClick={() => clickModifyDialog(item)}>
            수정
          </button>
        </td>
        <td>
          <button
            className={styles.rbutton}
            onClick={() => handleEventScheduleDeleteButtonClick(item.id)}>
            삭제
          </button>
        </td>
      </tr>
    );
  };

  return (
    <div className={styles.wrapper}>
      {isImageDialogOpen && imageUrlList !== null ? (
        <ImageDialog
          setIsImageDialogOpen={setIsImageDialogOpen}
          imageUrlList={imageUrlList}
        />
      ) : null}
      {isRegisterEventScheduleDialogOpen ? (
        <EventScheduleRegisterDialog
          setIsRegisterParticipatePlaceDialogOpen={
            setIsRegisterEventScheduleDialogOpen
          }
          category={category}
          intPage={intPage}
        />
      ) : null}
      {isModifyDialogOpen ? (
        <EventScheduleModifyDialog
          setIsModifyDialogOpen={setIsModifyDialogOpen}
          eventSchedule={eventSchedule}
          category={category}
          intPage={intPage}
        />
      ) : null}
      <div className={styles.innerWrapper}>
        <div className={styles.titleText}>행사 · 교육 관리</div>
        <div className={styles.aboveContainer}>
          <div className={styles.scrollContainer}>{menu}</div>
          <input
            type='text'
            className={styles.searchBox}
            placeholder='검색어 입력'
            onChange={searchInputHandler}
            onKeyDown={handleKeyDown}
            value={searchValue}
          />
          <div
            className={styles.searchButton}
            onClick={() => {
              searchClick();
            }}>
            검색
          </div>
        </div>
        <div className={styles.listContainer}>
          <div className={styles.totalCount}>전체 : {totalCount} 건</div>
          <dis className={styles.emptyFlex} />
          <div
            className={styles.button}
            onClick={() => {
              setIsRegisterEventScheduleDialogOpen(true);
            }}>
            추가하기
          </div>
        </div>
        <table className={styles.customTable}>
          <colgroup>
            {/* 컬럼 설정 */}
            <col style={{ minWidth: '120px' }}></col>
            <col style={{ minWidth: '170px' }}></col>
            <col style={{ minWidth: '150px' }}></col>
            <col style={{ minWidth: '200px' }}></col>
            <col style={{ minWidth: '170px' }}></col>
            <col style={{ minWidth: '200px' }}></col>
            <col style={{ minWidth: '200px' }}></col>
            <col style={{ minWidth: '200px' }}></col>
            <col style={{ minWidth: '170px' }}></col>
            <col style={{ minWidth: '150px' }}></col>
            <col style={{ minWidth: '200px' }}></col>
            <col style={{ minWidth: '400px' }}></col>
            <col style={{ minWidth: '170px' }}></col>
            <col style={{ minWidth: '110px' }}></col>
            <col style={{ minWidth: '110px' }}></col>
          </colgroup>
          <thead>
            <tr>
              <td>카테고리</td>
              <td>행사이름</td>
              <td>연락처</td>
              <td>링크</td>
              <td>지역</td>
              <td>장소</td>
              <td>지번주소</td>
              <td>도로명주소</td>
              <td>기간</td>
              <td>금액</td>
              <td>금액공지</td>
              <td>안내문구</td>
              <td>이미지</td>
              <td></td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {eventScheduleList.map((item, index) => (
              <EventScheduleRow key={index} item={item} />
            ))}
          </tbody>
        </table>
        <div className={styles.pagingContainer}>
          <Paging
            page={intPage}
            countPerPage={10}
            pageRangeDisplayed={5}
            count={totalCount}
            setPage={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default EventSchedule;
