import React, { useState, useEffect } from 'react';
import Paging from '../util/Paging';
import styles from './UserMachineLog.module.css';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import {
  downloadServiceLogExcel,
  getMachineLog,
  getMachineLogDetail,
} from '../../network/api/MachineAPI';
import { validateDates } from '../../utils/staticData';
import { dateChanger } from '../../utils/converter';

const searchOptions = [
  { value: 'username', label: '이용자 아이디' },
  { value: 'name', label: '기기명' },
  { value: 'serial', label: '일련번호' },
];

const searchFilterOptions = [
  { value: 'desc', label: '최신순' },
  { value: 'asc', label: '오래된순' },
];

const UserMachineLog = () => {
  const navigate = useNavigate();
  const [dataList, setDataList] = useState([]);
  const initialOption = searchFilterOptions[0];
  const [totalCount, setTotalCount] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedFilterOption, setSelectedFilterOption] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  // 페이지 번호는 정수로 변환, 기본값 1
  const intPage = new URLSearchParams(location.search).get('page') || 1;

  useEffect(() => {
    // URL의 쿼리 파라미터를 읽어와서 상태를 초기화
    const queryParams = new URLSearchParams(location.search);

    const start = queryParams.get('start');
    const end = queryParams.get('end');
    const search = queryParams.get('search') || '';
    const searchOption = queryParams.get('searchOption');
    const orderOption =
      queryParams.get('orderOption') || searchFilterOptions[0].value; // 기본값 설정

    // 페이지 번호는 정수로 변환, 기본값 1
    const intPage = parseInt(queryParams.get('page'), 10) || 1;

    // 시작기간 처리
    if (start) {
      setStartDate(start);
    } else {
      setStartDate(null);
    }

    // 종료기간 처리
    if (end) {
      setEndDate(end);
    } else {
      setEndDate(null);
    }

    // 상태 업데이트
    setSearchValue(search);
    setSelectedOption(
      searchOption
        ? {
            value: searchOption,
            label:
              {
                username: '이용자 아이디',
                name: '기기명',
                serialNumber: '일련번호',
              }[searchOption] || '',
          }
        : null
    );
    handleSetFilterOption(orderOption);

    // 페이지를 초기화하는 함수 호출
    getMachineLogInit(intPage, search, searchOption, orderOption, start, end);
  }, [location.search]);

  const getMachineLogInit = async (
    page,
    search,
    searchOption,
    orderOption,
    start,
    end
  ) => {
    const param = {
      page: page, // intPage로 설정된 페이지가 아닌 인자로 넘겨받은 page 사용
      size: 10,
      ...(searchOption && { [searchOption]: search }), // searchOption에 따라 조건 추가
      ...(orderOption && { orderOption }), // orderOption이 있으면 추가
      ...(start && { start }), // orderOption이 있으면 추가
      ...(end && { end }), // orderOption이 있으면 추가
    };
    const response = await getMachineLog(param);
    if (response) {
      setDataList(response.content);
      setTotalCount(response.totalElements);
    }
  };

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const searchInputHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchClick(false);
    }
  };

  const handleSetFilterOption = (searchOption) => {
    const foundOption = searchFilterOptions.find(
      (option) => option.value === searchOption
    );

    if (foundOption) {
      setSelectedFilterOption(foundOption);
    } else {
      setSelectedFilterOption(null);
    }
  };

  const getMachineLogDetailFunc = async (id) => {
    navigate(`/user-machine-log-detail/${id}`);
  };

  const startChange = (e) => {
    if (validateDates(e.target.value, endDate)) {
      const queryParams = new URLSearchParams(location.search);
      queryParams.set('start', e.target.value);

      // 이동
      navigate(`${location.pathname}?${queryParams.toString()}`);
    } else {
      alert('기간을 올바르게 설정해 주세요');
    }
  };

  const endChange = (e) => {
    if (validateDates(startDate, e.target.value)) {
      const queryParams = new URLSearchParams(location.search);
      queryParams.set('end', e.target.value);

      // 이동
      navigate(`${location.pathname}?${queryParams.toString()}`);
    } else {
      alert('기간을 올바르게 설정해 주세요');
    }
  };

  const handleFilterOptionChange = (selectedOption) => {
    const newOrder = selectedOption ? selectedOption.value : null;

    // 상태 업데이트
    setSelectedFilterOption(selectedOption);

    // 현재 URL의 쿼리 파라미터를 가져옴
    const queryParams = new URLSearchParams(location.search);

    // page 파라미터를 1로 초기화
    queryParams.set('page', '1');

    // order 파라미터를 처리
    queryParams.set('orderOption', newOrder);

    // 새로운 URL로 이동
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const searchClick = async () => {
    if (selectedOption !== null) {
      if (searchValue !== '') {
        if (selectedOption.value === 'serial') {
          if (!/^\d*$/.test(searchValue)) {
            alert('일련번호는 숫자만 입력해주세요');
            setSearchValue('');
            return;
          }
        }

        const queryParams = new URLSearchParams(location.search);
        queryParams.set('page', '1');
        if (searchValue) {
          queryParams.set('searchOption', selectedOption.value);
          queryParams.set('search', searchValue);
        } else {
          queryParams.delete('search');
          queryParams.delete('searchOption');
        }

        navigate(`${location.pathname}?${queryParams.toString()}`);
      } else {
        alert('검색할 내용을 입력해주세요');
      }
    } else {
      alert('검색할 옵션을 선택해주세요');
    }
  };

  const resetOption = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('page', '1');
    queryParams.delete('search');
    queryParams.delete('start');
    queryParams.delete('end');
    queryParams.delete('searchOption');
    queryParams.delete('orderOption');
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const handlePageChange = async (page) => {
    // 기본 URL 설정
    let url = '/user-machine-log';

    // 현재 URL의 쿼리 파라미터를 가져옴
    const queryParams = new URLSearchParams(location.search);

    // page 파라미터를 업데이트
    if (page) {
      queryParams.set('page', page);
    }

    // URL에 쿼리 파라미터 추가
    if (queryParams.toString()) {
      url += `?${queryParams.toString()}`;
    }

    // 이동
    navigate(url);
  };

  const downloadExcel = async () => {
    const queryParams = new URLSearchParams(location.search);

    const search = queryParams.get('search') || '';
    const searchOption = queryParams.get('searchOption');
    const start = queryParams.get('start');
    const end = queryParams.get('end');
    const orderOption =
      queryParams.get('orderOption') || searchFilterOptions[0].value; // 기본값 설정

    const param = {
      ...(searchOption && { [searchOption]: search }), // searchOption에 따라 조건 추가
      ...(orderOption && { orderOption }), // orderOption이 있으면 추가
      ...(start && { start }), // orderOption이 있으면 추가
      ...(end && { end }), // orderOption이 있으면 추가
    };

    const response = await downloadServiceLogExcel(param);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div className={styles.titleText}>서비스 이용내역 관리</div>
        <div className={styles.filterSection}>
          <input
            name='startDate'
            type='date'
            className={styles.selectBox}
            value={startDate}
            onChange={startChange}
          />
          <div>~</div>
          <input
            name='endDate'
            type='date'
            className={styles.selectBox}
            value={endDate}
            onChange={endChange}
          />
          <Select
            value={selectedOption}
            onChange={handleOptionChange}
            options={searchOptions}
            placeholder='선택'
          />
          <input
            type='text'
            className={styles.searchBox}
            placeholder='입력'
            onChange={searchInputHandler}
            onKeyDown={handleKeyDown}
            value={searchValue}
          />
          <button
            onClick={() => {
              searchClick();
            }}
            className={styles.searchButton}>
            검색
          </button>
          <button
            className={styles.searchButton}
            onClick={() => {
              resetOption();
            }}>
            초기화
          </button>
          <div
            className={styles.excelButton}
            onClick={() => {
              downloadExcel();
            }}>
            엑셀 다운로드
          </div>
        </div>
        <div className={styles.listContainer}>
          <div className={styles.totalCount}>전체 : {totalCount} 건</div>
          <div className={styles.filterBox}>
            <p>정렬</p>
            <Select
              value={selectedFilterOption || initialOption}
              onChange={handleFilterOptionChange}
              options={searchFilterOptions}
              placeholder='선택'
            />
          </div>
        </div>
        <table className={styles.customTable}>
          <colgroup>
            <col style={{ minWidth: '70px' }}></col>
            <col style={{ minWidth: '70px' }}></col>
            <col style={{ minWidth: '150px' }}></col>
            <col style={{ minWidth: '180px' }}></col>
            <col style={{ minWidth: '100px' }}></col>
            <col style={{ minWidth: '100px' }}></col>
            <col style={{ minWidth: '120px' }}></col>
          </colgroup>
          <thead>
            <tr>
              <th>ID</th>
              <th>이용자ID</th>
              <th>이용일시</th>
              <th>지역</th>
              <th>기기명</th>
              <th>일련번호</th>
              <th>기기구분</th>
            </tr>
          </thead>
          <tbody>
            {dataList.map((item, index) => (
              <tr
                key={index}
                onClick={() => {
                  getMachineLogDetailFunc(item.id);
                }}>
                <td>{item.id}</td>
                <td>{item.userId}</td>
                <td>{dateChanger(item.useTime)}</td>
                <td>{item.address}</td>
                <td>{item.machineName}</td>
                <td>{item.serialNumber}</td>
                <td>{item.category}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.pagingContainer}>
          <Paging
            page={parseInt(intPage, 10)}
            countPerPage={10}
            pageRangeDisplayed={5}
            count={totalCount}
            setPage={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

UserMachineLog.propTypes = {};

export default UserMachineLog;
