import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import styles from './VisitPlace.module.css';
import {
  getVisitPlaceCategory,
  getVisitPlaceData,
  deleteVisitPlaceCategory,
  deleteVisitPlace,
} from '../../network/api/VisitPlaceAPI';
import Paging from '../util/Paging';
import CategoryDialog from './CategoryDialog';
import ImageDialog from '../util/ImageDialog';
import VisitPlaceModifyDialog from './VisitPlaceModifyDialog';
import VisitPlaceRegisterDialog from './VisitPlaceRegisterDialog';

const VisitPlace = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [categoryList, setCategoryList] = useState([]);
  const [visitPlaceList, setVisitPlaceList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [category, setCategory] = useState('');
  const [categoryId, setCategoryId] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [visitPlace, setVisitPlace] = useState(null);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [isModifyDialogOpen, setIsModifyDialogOpen] = useState(false);
  const [isRegisterCategoryDialogOpen, setIsRegisterCategoryDialogOpen] =
    useState(false);
  const [isModifyCategoryDialogOpen, setIsModifyCategoryDialogOpen] =
    useState(false);
  const [isRegisterVisitPlaceDialogOpen, setIsRegisterVisitPlaceDialogOpen] =
    useState(false);
  const { page } = useParams();
  let intPage = parseInt(page);

  useEffect(() => {
    getVisitPlaceInit();
  }, [intPage]);

  const menu = categoryList.map((categoryItem, index) => (
    <div key={index}>
      <div
        className={
          categoryItem.isSelected ? styles.slectedMenuItem : styles.menuItem
        }
        onClick={() => {
          handleToggleCategoryList(categoryItem.category);
        }}>
        {categoryItem.category}
      </div>
      {categoryList.length - 1 !== index ? (
        <div className={styles.rowBox}>
          <div
            className={styles.modifyMenu}
            onClick={() => {
              modifyButtonClick(categoryItem);
            }}>
            <svg
              className={styles.pencilIcon}
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M12.5 7.5L5.92819 14.0718C5.71566 14.2843 5.60939 14.3906 5.53953 14.5212C5.46966 14.6517 5.44019 14.7991 5.38124 15.0938L4.64709 18.7646C4.58057 19.0972 4.5473 19.2635 4.64191 19.3581C4.73652 19.4527 4.90283 19.4194 5.23544 19.3529L8.90621 18.6188C9.20093 18.5598 9.3483 18.5303 9.47885 18.4605C9.60939 18.3906 9.71566 18.2843 9.92819 18.0718L16.5 11.5L12.5 7.5Z'
                fill='#F7F8FA'
              />
              <path
                d='M5.95396 19.38L5.95397 19.38L5.9801 19.3734L5.98012 19.3734L8.60809 18.7164C8.62428 18.7124 8.64043 18.7084 8.65654 18.7044C8.87531 18.65 9.08562 18.5978 9.27707 18.4894C9.46852 18.381 9.62153 18.2275 9.7807 18.0679C9.79242 18.0561 9.80418 18.0444 9.81598 18.0325L17.0101 10.8385L17.0101 10.8385L17.0369 10.8117C17.3472 10.5014 17.6215 10.2272 17.8128 9.97638C18.0202 9.70457 18.1858 9.39104 18.1858 9C18.1858 8.60896 18.0202 8.29543 17.8128 8.02361C17.6215 7.77285 17.3472 7.49863 17.0369 7.18835L17.01 7.16152L16.8385 6.98995L16.8117 6.96314C16.5014 6.6528 16.2272 6.37853 15.9764 6.1872C15.7046 5.97981 15.391 5.81421 15 5.81421C14.609 5.81421 14.2954 5.97981 14.0236 6.1872C13.7729 6.37853 13.4986 6.65278 13.1884 6.96311L13.1615 6.98995L5.96745 14.184C5.95565 14.1958 5.94386 14.2076 5.93211 14.2193C5.77249 14.3785 5.61904 14.5315 5.51064 14.7229C5.40225 14.9144 5.34999 15.1247 5.29562 15.3435C5.29162 15.3596 5.28761 15.3757 5.28356 15.3919L4.62003 18.046C4.61762 18.0557 4.61518 18.0654 4.61272 18.0752C4.57411 18.2293 4.53044 18.4035 4.51593 18.5518C4.49978 18.7169 4.50127 19.0162 4.74255 19.2574C4.98383 19.4987 5.28307 19.5002 5.44819 19.4841C5.59646 19.4696 5.77072 19.4259 5.92479 19.3873C5.9346 19.3848 5.94433 19.3824 5.95396 19.38Z'
                stroke='#DBDBDB'
                strokeWidth='1.2'
              />
              <path
                d='M12.5 7.5L16.5 11.5'
                stroke='#DBDBDB'
                strokeWidth='1.2'
              />
            </svg>
          </div>
          <div
            className={styles.deleteMenu}
            onClick={() => {
              handleCategoryDeleteButtonClick(categoryItem);
            }}>
            <svg
              className={styles.deleteIcon}
              width='26'
              height='26'
              viewBox='0 0 26 26'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M19.0097 6.98975L6.98926 19.0102'
                stroke='#FFFFFF'
                strokeWidth='3'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M6.98936 6.98975L19.0098 19.0102'
                stroke='#FFFFFF'
                strokeWidth='3'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
        </div>
      ) : null}
    </div>
  ));

  const getVisitPlaceInit = async () => {
    const response = await getVisitPlaceCategory();
    let firstCategory = '';
    if (response) {
      setCategoryList([]);
      if (location.state !== null) {
        response.map(async (resource, index) => {
          if (location.state === resource.category) {
            setCategory(resource.category);
            const category = {
              id: resource.id,
              category: resource.category,
              isSelected: true,
            };
            firstCategory = resource.category;
            setCategoryList((prevList) => [...prevList, category]);
          } else {
            const category = {
              id: resource.id,
              category: resource.category,
              isSelected: false,
            };
            setCategoryList((prevList) => [...prevList, category]);
          }
        });
      } else {
        response.map(async (resource, index) => {
          if (index === 0) {
            setCategory(resource.category);
            const category = {
              id: resource.id,
              category: resource.category,
              isSelected: true,
            };
            firstCategory = resource.category;
            setCategoryList((prevList) => [...prevList, category]);
          } else {
            const category = {
              id: resource.id,
              category: resource.category,
              isSelected: false,
            };
            setCategoryList((prevList) => [...prevList, category]);
          }
        });
      }

      setCategoryList((prevList) => [
        ...prevList,
        {
          category: '+',
          isSelected: false,
        },
      ]);
      if (firstCategory !== '') {
        await getVisitPlace(firstCategory);
      }
    }

    setIsLoaded(true);
  };

  const handleToggleCategoryList = (category) => {
    if (category === '+') {
      setIsRegisterCategoryDialogOpen(true);
    } else {
      if (intPage === 1) {
        setCategoryList(
          categoryList.map((item) => {
            if (item.category === category) {
              if (!item.isSelected) {
                return { ...item, isSelected: !item.isSelected };
              } else {
                return { ...item, isSelected: item.isSelected };
              }
            } else {
              return { ...item, isSelected: false };
            }
          })
        );
        intPage = 1;
        setCategory(category);
        getVisitPlace(category);
      } else {
        navigate(`/visit-place/${1}`, { state: category });
      }
    }
  };

  const handlePageChange = async (page) => {
    navigate(`/visit-place/${page}`, { state: category });
  };

  const clickOpenDialog = (imageUrl) => {
    setImageUrl(imageUrl);
    setIsImageDialogOpen(true);
  };

  const clickModifyDialog = (visitPlace) => {
    setVisitPlace(visitPlace);
    setIsModifyDialogOpen(true);
  };

  const modifyButtonClick = (item) => {
    setCategoryId(item.id);
    setCategory(item.category);
    setIsModifyCategoryDialogOpen(true);
  };

  const deleteCategory = async (item) => {
    const response = await deleteVisitPlaceCategory({ id: item.id });
    if (response === 'success') {
      alert('삭제완료');
      window.location.reload(true);
    }
  };

  const handleVisitPlaceDeleteButtonClick = (id) => {
    const result = window.confirm('정말 삭제하시겠습니까?');

    if (result) {
      deleteVisitPlaceFunc(id);
    } else {
      return;
    }
  };

  const handleCategoryDeleteButtonClick = (item) => {
    const result = window.confirm(
      '카테고리를 삭제하시면 하위 장소들도 사라지게 됩니다. 정말 삭제하시겠습니까?'
    );

    if (result) {
      deleteCategory(item);
    } else {
      return;
    }
  };

  const deleteVisitPlaceFunc = async (id) => {
    const response = await deleteVisitPlace({ id: id });
    if (response === 'success') {
      alert('삭제완료');
      window.location.reload(true);
    } else {
      alert('삭제실패');
    }
  };

  const getVisitPlace = async (category) => {
    const response = await getVisitPlaceData({
      page: intPage,
      size: 10,
      category: category,
    });
    if (response) {
      setVisitPlaceList(response.content);
      setTotalCount(response.totalElements);
    }
  };

  return (
    <div className={styles.wrapper}>
      {isImageDialogOpen && imageUrl !== null ? (
        <ImageDialog
          setIsImageDialogOpen={setIsImageDialogOpen}
          imageUrl={imageUrl}
          type='image'
        />
      ) : null}
      {isModifyDialogOpen && visitPlace !== null ? (
        <VisitPlaceModifyDialog
          setIsModifyDialogOpen={setIsModifyDialogOpen}
          visitPlace={visitPlace}
          intPage={intPage}
        />
      ) : null}
      {isRegisterVisitPlaceDialogOpen ? (
        <VisitPlaceRegisterDialog
          setIsRegisterVisitPlaceDialogOpen={setIsRegisterVisitPlaceDialogOpen}
          visitPlace={visitPlace}
          category={category}
          intPage={intPage}
        />
      ) : null}
      {(categoryId !== null && isModifyCategoryDialogOpen) ||
      isRegisterCategoryDialogOpen ? (
        <CategoryDialog
          where={'visitPlace'}
          categoryId={categoryId}
          category={category}
          isRegisterCategoryDialogOpen={isRegisterCategoryDialogOpen}
          setIsRegisterCategoryDialogOpen={setIsRegisterCategoryDialogOpen}
          setIsModifyCategoryDialogOpen={setIsModifyCategoryDialogOpen}
        />
      ) : null}
      <div className={styles.innerWrapper}>
        <div className={styles.titleText}>가볼 만한 곳 관리</div>
        <div className={styles.aboveContainer}>
          <div className={styles.scrollContainer}>{menu}</div>
        </div>
        <div className={styles.listContainer}>
          <div className={styles.totalCount}>전체 : {totalCount} 건</div>
          <dis className={styles.emptyFlex} />
          <div
            className={styles.button}
            onClick={() => {
              setIsRegisterVisitPlaceDialogOpen(true);
            }}>
            추가하기
          </div>
        </div>
        <table className={styles.customTable}>
          <colgroup>
            <col style={{ minWidth: '120px' }}></col>
            <col style={{ minWidth: '150px' }}></col>
            <col style={{ minWidth: '150px' }}></col>
            <col style={{ minWidth: '200px' }}></col>
            <col style={{ minWidth: '170px' }}></col>
            <col style={{ minWidth: '110px' }}></col>
            <col style={{ minWidth: '110px' }}></col>
          </colgroup>
          <thead>
            <tr>
              <td>카테고리</td>
              <td>링크</td>
              <td>지역</td>
              <td>안내문구</td>
              <td>이미지</td>
              <td></td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {visitPlaceList.map((item, index) => (
              <tr key={index}>
                <td>{item.category}</td>
                <td>
                  <span
                    className={styles.linkStyle}
                    onClick={() => {
                      window.open(item.linkUrl, '_blank');
                    }}>
                    {item.linkUrl}
                  </span>
                </td>
                <td>{item.location}</td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: item.notice,
                  }}></td>
                <td>
                  <button
                    className={styles.button}
                    onClick={() => {
                      clickOpenDialog(item.imageUrl);
                    }}>
                    이미지 보기
                  </button>
                </td>
                <td>
                  <button
                    className={styles.button}
                    onClick={() => {
                      clickModifyDialog(item);
                    }}>
                    수정
                  </button>
                </td>
                <td>
                  <button
                    className={styles.rbutton}
                    onClick={() => handleVisitPlaceDeleteButtonClick(item.id)}>
                    삭제
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.pagingContainer}>
          <Paging
            page={intPage}
            countPerPage={10}
            pageRangeDisplayed={5}
            count={totalCount}
            setPage={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default VisitPlace;
