import React, { useState, useEffect } from 'react';
import Paging from '../util/Paging';
import styles from './VendingMachine.module.css';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { getMachine } from '../../network/api/MachineAPI';
import { regionDistricts } from '../../utils/staticData';
import MachineDialog from '../util/MachineDialog';
import Select from 'react-select';
import ImageDialog from '../util/ImageDialog';

const searchOptions = [
  { value: 'machineName', label: '기기명' },
  { value: 'number', label: '일련번호' },
];

const searchFilterOptions = [
  { value: 'desc', label: '최신순' },
  { value: 'asc', label: '오래된순' },
];

const VendingMachine = () => {
  const navigate = useNavigate();
  const initialOption = searchFilterOptions[0];
  const [selectData, setSelectData] = useState(null);
  const [dataList, setDataList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filters, setFilters] = useState({
    city: '',
    district: '',
    category: '',
    cbStatus: '',
    vmStatus: '',
    cbReleaseStatus: '',
    vmReleaseStatus: '',
  });
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedFilterOption, setSelectedFilterOption] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState({
    isRegister: false,
    isOpen: false,
  });
  const [image, setImage] = useState(null);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);

  // 페이지 번호는 정수로 변환, 기본값 1
  const intPage = new URLSearchParams(location.search).get('page') || 1;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const filters = {
      category: queryParams.get('category'),
      city: queryParams.get('city'),
      district: queryParams.get('district'),
      cbStatus: queryParams.get('cbStatus'),
      vmStatus: queryParams.get('vmStatus'),
      releaseStatus:
        queryParams.get('releaseStatus') === null
          ? null
          : queryParams.get('releaseStatus') === 'true',
    };

    const search = queryParams.get('search') || '';
    const searchOption = queryParams.get('searchOption');
    const orderOption =
      queryParams.get('orderOption') || searchFilterOptions[0].value; // 기본값 설정
    const page = parseInt(queryParams.get('page'), 10) || 1;

    setFilters((prev) => ({ ...prev, ...filters }));
    setSearchValue(search);

    setSelectedOption(
      searchOption
        ? {
            value: searchOption,
            label: { machineName: '기기명', number: '일련번호' }[searchOption],
          }
        : null
    );
    handleSetFilterOption(orderOption);

    getPooPlaceInit(page, { orderOption, searchOption, search, ...filters });
  }, [location.search]);

  const getPooPlaceInit = async (
    page,
    {
      orderOption,
      searchOption,
      search,
      category,
      city,
      district,
      cbStatus,
      vmStatus,
      releaseStatus,
    }
  ) => {
    try {
      const response = await getMachine({
        page,
        size: 10,
        category,
        city,
        district,
        cbStatus,
        vmStatus,
        releaseStatus,
        ...(searchOption && { [searchOption]: search }), // searchOption에 따라 조건 추가
        ...(orderOption && { orderOption }), // orderOption이 있으면 추가
      });
      if (response) {
        setDataList(response.content);
        setTotalCount(response.totalElements);
      }
    } catch (error) {
      console.error('데이터 불러오기 실패:', error);
    }
  };

  const handleSetFilterOption = (searchOption) => {
    const foundOption = searchFilterOptions.find(
      (option) => option.value === searchOption
    );

    if (foundOption) {
      setSelectedFilterOption(foundOption);
    } else {
      setSelectedFilterOption(null);
    }
  };

  const machineDialogHandler = (isRegister, item) => {
    if (!isRegister) {
      setSelectData(item);
    }
    setIsDialogOpen({ isRegister: isRegister, isOpen: true });
  };

  const handleFilterOptionChange = (selectedOption) => {
    const newOrder = selectedOption ? selectedOption.value : null;

    // 현재 URL의 쿼리 파라미터를 가져옴
    const queryParams = new URLSearchParams(location.search);

    // page 파라미터를 1로 초기화
    queryParams.set('page', '1');

    // order 파라미터를 처리
    queryParams.set('orderOption', newOrder);

    // 새로운 URL로 이동
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;

    // 현재 URL의 쿼리 파라미터를 가져옴
    const queryParams = new URLSearchParams(location.search);

    // page 파라미터를 1로 초기화
    queryParams.set('page', '1');

    // 필터 상태 업데이트
    setFilters({
      ...filters,
      [name]: value,
    });

    if (value === '') {
      queryParams.delete(name);
    } else {
      queryParams.set(name, value);
    }

    // 새로운 URL로 이동
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const searchClick = async () => {
    if (selectedOption !== null) {
      if (searchValue !== '') {
        if (selectedOption.value === 'number') {
          if (!/^\d*$/.test(searchValue)) {
            alert('일련번호는 숫자만 입력해주세요');
            setSearchValue('');
            return;
          }
        }

        const queryParams = new URLSearchParams(location.search);
        queryParams.set('page', '1');
        if (searchValue) {
          queryParams.set('searchOption', selectedOption.value);
          queryParams.set('search', searchValue);
        } else {
          queryParams.delete('search');
          queryParams.delete('searchOption');
        }

        navigate(`${location.pathname}?${queryParams.toString()}`);
      } else {
        alert('검색할 내용을 입력해주세요');
      }
    } else {
      alert('검색할 옵션을 선택해주세요');
    }
  };

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const searchInputHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchClick(false);
    }
  };

  const handlePageChange = async (page) => {
    // 기본 URL 설정
    let url = '/machine';

    // 현재 URL의 쿼리 파라미터를 가져옴
    const queryParams = new URLSearchParams(location.search);

    // page 파라미터를 업데이트
    if (page) {
      queryParams.set('page', page);
    }

    // URL에 쿼리 파라미터 추가
    if (queryParams.toString()) {
      url += `?${queryParams.toString()}`;
    }

    // 이동
    navigate(url);
  };

  const resetOption = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('page', '1');
    queryParams.delete('search');
    queryParams.delete('searchOption');
    queryParams.delete('orderOption');
    queryParams.delete('category');
    queryParams.delete('city');
    queryParams.delete('district');
    queryParams.delete('cbStatus');
    queryParams.delete('vmStatus');
    queryParams.delete('releaseStatus');

    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const clickOpenDialog = (image) => {
    if (image !== null && image !== undefined && image !== '') {
      setImage(image);
      setIsImageDialogOpen(true);
    } else {
      alert('등록된 이미지가 없습니다');
    }
  };

  return (
    <div className={styles.wrapper}>
      {isImageDialogOpen && image !== null ? (
        <ImageDialog
          setIsImageDialogOpen={setIsImageDialogOpen}
          imageUrl={image}
          type='image'
        />
      ) : null}
      <div className={styles.innerWrapper}>
        {isDialogOpen.isOpen ? (
          <MachineDialog
            category={'자판기'}
            selectData={selectData}
            isRegisterDialog={isDialogOpen.isRegister}
            setIsDialogOpen={setIsDialogOpen}
          />
        ) : null}
        <div className={styles.titleText}>기기 관리</div>
        <div className={styles.filterSection}>
          <select
            name='city'
            value={filters.city}
            onChange={handleFilterChange}
            className={styles.selectBox}>
            <option value=''>지역</option>
            {Object.keys(regionDistricts).map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
          <select
            name='district'
            value={filters.district}
            onChange={handleFilterChange}
            className={styles.selectBox}>
            <option value=''>시/군/구</option>
            {filters.city &&
              regionDistricts[filters.city].map((district) => (
                <option key={district} value={district}>
                  {district}
                </option>
              ))}
          </select>
          <select
            name='category'
            value={filters.category}
            onChange={handleFilterChange}
            className={styles.selectBox}>
            <option value=''>구분</option>
            <option value='tmach'>일체형</option>
            <option value='vmach'>자판기</option>
            <option value='cbobx'>수거함</option>
          </select>
          <select
            name='vmStatus'
            value={filters.vmStatus}
            onChange={handleFilterChange}
            className={styles.selectBox}>
            <option value=''>자판기 상태</option>
            <option value='active'>사용가능</option>
            <option value='maintenance'>점검중</option>
            <option value='disable'>사용불가</option>
          </select>
          <select
            name='cbStatus'
            value={filters.cbStatus}
            onChange={handleFilterChange}
            className={styles.selectBox}>
            <option value=''>수거함 상태</option>
            <option value='active'>사용가능</option>
            <option value='maintenance'>점검중</option>
            <option value='disable'>사용불가</option>
          </select>
          <select
            name='releaseStatus'
            value={filters.releaseStatus}
            onChange={handleFilterChange}
            className={styles.selectBox}>
            <option value=''>노출여부</option>
            <option value={true}>노출</option>
            <option value={false}>비노출</option>
          </select>
          <Select
            value={selectedOption}
            onChange={handleOptionChange}
            options={searchOptions}
            placeholder='선택'
          />
          <input
            type='text'
            className={styles.searchBox}
            placeholder='입력'
            onChange={searchInputHandler}
            onKeyDown={handleKeyDown}
            value={searchValue}
          />
          <button
            onClick={() => {
              searchClick(false);
            }}
            className={styles.searchButton}>
            검색
          </button>
          <button
            className={styles.searchButton}
            onClick={() => {
              resetOption();
            }}>
            초기화
          </button>
        </div>
        <div className={styles.listContainer}>
          <div className={styles.totalCount}>전체 : {totalCount} 건</div>
          <div className={styles.filterBox}>
            <div
              className={styles.registerButton}
              onClick={() => {
                machineDialogHandler(true);
              }}>
              기기 등록
            </div>
            <p>정렬</p>
            <Select
              value={selectedFilterOption || initialOption}
              onChange={handleFilterOptionChange}
              options={searchFilterOptions}
              placeholder='선택'
            />
          </div>
        </div>
        <table className={styles.customTable}>
          <colgroup>
            <col style={{ minWidth: '70px' }}></col>
            <col style={{ minWidth: '100px' }}></col>
            <col style={{ minWidth: '200px' }}></col>
            <col style={{ minWidth: '100px' }}></col>
            <col style={{ minWidth: '150px' }}></col>
            <col style={{ minWidth: '200px' }}></col>
            <col style={{ minWidth: '100px' }}></col>
            <col style={{ minWidth: '80px' }}></col>
            <col style={{ minWidth: '80px' }}></col>
          </colgroup>
          <thead>
            <tr>
              <th>ID</th>
              <th>기기구분</th>
              <th>지역</th>
              <th>일련번호</th>
              <th>기기명</th>
              <th>이미지 보기</th>
              <th>상태</th>
              <th>노출여부</th>
              <th>잔여량(자판기)</th>
              <th>잔여량(수거함)</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
            {dataList.map((item, index) => (
              <tr key={index}>
                <td>{item.id}</td>
                <td>
                  {item.category === 'tmach'
                    ? '일체형'
                    : item.category === 'vmach'
                    ? '자판기'
                    : '수거함'}
                </td>
                <td>{item.address}</td>
                <td>{item.serialNumber}</td>
                <td>{item.machineName}</td>
                <td>
                  <button
                    className={styles.button}
                    onClick={() => {
                      clickOpenDialog(item.imageUrl);
                    }}>
                    이미지 보기
                  </button>
                </td>
                <td>
                  {item.vmStatus !== null
                    ? item.vmStatus === 'active'
                      ? '사용가능'
                      : item.vmStatus === 'maintenance'
                      ? '점검중'
                      : '사용불가'
                    : '-'}{' '}
                  /{' '}
                  {item.cbStatus !== null
                    ? item.cbStatus === 'active'
                      ? '사용가능'
                      : item.cbStatus === 'maintenance'
                      ? '점검중'
                      : '사용불가'
                    : '-'}
                </td>
                <td>
                  {item.vmReleaseStatus !== null
                    ? item.vmReleaseStatus
                      ? '노출'
                      : '비노출'
                    : '-'}{' '}
                  /{' '}
                  {item.cbReleaseStatus !== null
                    ? item.cbReleaseStatus
                      ? '노출'
                      : '비노출'
                    : '-'}
                </td>
                <td>{item.remainAmount}</td>
                <td>{item.cboxRemainAmount}</td>
                <td className={styles.modifyTd}>
                  <a
                    onClick={() => {
                      machineDialogHandler(false, item);
                    }}
                    className={styles.modifyButton}>
                    수정
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.pagingContainer}>
          <Paging
            page={parseInt(intPage, 10)}
            countPerPage={10}
            pageRangeDisplayed={5}
            count={totalCount}
            setPage={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

VendingMachine.propTypes = {
  category: PropTypes.string,
};

export default VendingMachine;
