import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Paging from '../util/Paging';
import styles from './Advertisement.module.css';
import ImageDialog from '../util/ImageDialog';
import Select from 'react-select';
import { dateChanger } from '../../utils/converter';
import { deleteAd, getAdvertisementList } from '../../network/api/MachineAPI';
import AdvertisementModal from './AdvertisementModal';

const typeOptions = [
  { value: 'all', label: '광고타입 선택' },
  { value: 'image', label: '이미지' },
  { value: 'video', label: '비디오' },
];

const searchFilterOptions = [
  { value: 'desc', label: '최신순' },
  { value: 'asc', label: '오래된순' },
];

const Advertisement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const initialOption = searchFilterOptions[0];
  const initialTypeOption = typeOptions[0];
  const [selectedFilterOption, setSelectedFilterOption] = useState(null);
  const [typeOption, setTypeOption] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [adList, setAdList] = useState([]);
  const [image, setImage] = useState(null);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedAd, setSelectedAd] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // 페이지 번호는 정수로 변환, 기본값 1
  const intPage = new URLSearchParams(location.search).get('page') || 1;

  useEffect(() => {
    // URL의 쿼리 파라미터를 읽어와서 상태를 초기화
    const queryParams = new URLSearchParams(location.search);

    const search = queryParams.get('search') || '';
    const orderOption =
      queryParams.get('orderOption') || searchFilterOptions[0].value; // 기본값 설정
    const typeOption = queryParams.get('typeOption') || typeOptions[0].value; // 기본값 설정

    // 페이지 번호는 정수로 변환, 기본값 1
    const intPage = parseInt(queryParams.get('page'), 10) || 1;

    // 상태 업데이트
    setSearchValue(search);
    handleSetTypeOption(typeOption);
    handleSetFilterOption(orderOption);

    // 페이지를 초기화하는 함수 호출
    getUserListInit(intPage, search, typeOption, orderOption);
  }, [location.search]);

  const getUserListInit = async (page, search, typeOption, orderOption) => {
    const param = {
      page: page, // intPage로 설정된 페이지가 아닌 인자로 넘겨받은 page 사용
      size: 10,
      number: search,
      ...(typeOption !== 'all' && { type: typeOption }),
      ...(orderOption && { orderOption }), // orderOption이 있으면 추가
    };

    const response = await getAdvertisementList(param);
    if (response) {
      setAdList(response.content);
      setTotalCount(response.totalElements);
    }
  };

  const handleSetTypeOption = (searchOption) => {
    const foundOption = typeOptions.find(
      (option) => option.value === searchOption
    );

    if (foundOption) {
      setTypeOption(foundOption);
    } else {
      setTypeOption(null);
    }
  };

  const handleSetFilterOption = (searchOption) => {
    const foundOption = searchFilterOptions.find(
      (option) => option.value === searchOption
    );

    if (foundOption) {
      setSelectedFilterOption(foundOption);
    } else {
      setSelectedFilterOption(null);
    }
  };

  const searchInputHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchClick(false);
    }
  };

  const handlePageChange = async (page) => {
    // 기본 URL 설정
    let url = '/advertisement';

    // 현재 URL의 쿼리 파라미터를 가져옴
    const queryParams = new URLSearchParams(location.search);

    // page 파라미터를 업데이트
    if (page) {
      queryParams.set('page', page);
    }

    // URL에 쿼리 파라미터 추가
    if (queryParams.toString()) {
      url += `?${queryParams.toString()}`;
    }

    // 이동
    navigate(url);
  };

  const clickOpenDialog = (image) => {
    if (image !== null && image !== undefined && image !== '') {
      setImage(image);
      setIsImageDialogOpen(true);
    } else {
      alert('등록된 이미지가 없습니다');
    }
  };

  const handleFilterOptionChange = (selectedOption) => {
    const newOrder = selectedOption ? selectedOption.value : null;

    // 현재 URL의 쿼리 파라미터를 가져옴
    const queryParams = new URLSearchParams(location.search);

    // page 파라미터를 1로 초기화
    queryParams.set('page', '1');

    // order 파라미터를 처리
    queryParams.set('orderOption', newOrder);

    // 새로운 URL로 이동
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const handleTypeOptionChange = (selectedOption) => {
    const newType = selectedOption ? selectedOption.value : null;

    // 현재 URL의 쿼리 파라미터를 가져옴
    const queryParams = new URLSearchParams(location.search);

    // page 파라미터를 1로 초기화
    queryParams.set('page', '1');

    // type 파라미터를 처리
    if (newType === 'all') {
      queryParams.delete('typeOption');
    } else {
      queryParams.set('typeOption', newType);
    }

    // 새로운 URL로 이동
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const searchClick = async () => {
    if (searchValue !== '') {
      if (!/^\d*$/.test(searchValue)) {
        alert('휴대폰번호는 숫자만 입력해주세요');
        setSearchValue('');
        return;
      }

      const queryParams = new URLSearchParams(location.search);
      queryParams.set('page', '1');
      if (searchValue) {
        queryParams.set('search', searchValue);
      } else {
        queryParams.delete('search');
      }

      navigate(`${location.pathname}?${queryParams.toString()}`);
    } else {
      alert('검색할 내용을 입력해주세요');
    }
  };

  const resetOption = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('page', '1');
    queryParams.delete('search');
    queryParams.delete('searchOption');
    queryParams.delete('orderOption');
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const deleteClick = (id) => {
    const result = window.confirm('정말 삭제하시겠습니까?');

    if (result) {
      deleteAdFunc(id);
    } else {
      return;
    }
  };

  const deleteAdFunc = async (id) => {
    const response = await deleteAd(id);
    if (response === 'success') {
      alert('삭제 성공');
      window.location.reload(true);
    } else {
      alert('삭제 실패');
    }
  };

  return (
    <div className={styles.wrapper}>
      {isModalOpen && (
        <AdvertisementModal
          advertisement={selectedAd}
          setIsModalOpen={setIsModalOpen}
          setAd={setSelectedAd}
          number={null}
        />
      )}
      {isImageDialogOpen && image !== null ? (
        <ImageDialog
          setIsImageDialogOpen={setIsImageDialogOpen}
          imageUrl={image}
          type={selectedAd.type}
        />
      ) : null}
      <div className={styles.innerWrapper}>
        <div className={styles.titleText}>광고관리</div>
        <div className={styles.filterSection}>
          <Select
            value={typeOption || initialTypeOption}
            onChange={handleTypeOptionChange}
            options={typeOptions}
            placeholder='선택'
          />
          <input
            type='text'
            className={styles.searchBox}
            placeholder='기기 일련번호'
            onChange={searchInputHandler}
            onKeyDown={handleKeyDown}
            value={searchValue}
          />
          <div
            className={styles.searchButton}
            onClick={() => {
              searchClick(false);
            }}>
            검색
          </div>
          <div
            className={styles.searchButton}
            onClick={() => {
              resetOption();
            }}>
            초기화
          </div>
        </div>
        <div className={styles.listContainer}>
          <div className={styles.totalCount}>전체 : {totalCount} 건</div>
          <div className={styles.filterBox}>
            <div
              className={styles.modifyButton}
              onClick={() => {
                setSelectedAd(null);
                setIsModalOpen(true);
              }}>
              전체노출 광고등록
            </div>
            <p>정렬</p>
            <Select
              value={selectedFilterOption || initialOption}
              onChange={handleFilterOptionChange}
              options={searchFilterOptions}
              placeholder='선택'
            />
          </div>
        </div>
        <table className={styles.customTable}>
          <colgroup>
            <col style={{ minWidth: '80px' }}></col>
            <col style={{ minWidth: '80px' }}></col>
            <col style={{ minWidth: '150px' }}></col>
            <col style={{ minWidth: '80px' }}></col>
            <col style={{ minWidth: '80px' }}></col>
            <col style={{ minWidth: '80px' }}></col>
            <col style={{ minWidth: '100px' }}></col>
            <col style={{ minWidth: '100px' }}></col>
            <col style={{ minWidth: '100px' }}></col>
          </colgroup>
          <thead>
            <tr>
              <th>ID</th>
              <th>상태</th>
              <th>기기 일련번호</th>
              <th>타입</th>
              <th>광고 보기</th>
              <th>버전</th>
              <th>등록일</th>
              <th>최근 수정일</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {adList.map((item, index) => (
              <tr key={index}>
                <td>{item.id}</td>
                <td>{item.activated ? '활성' : '비활성'}</td>
                <td>{item.target}</td>
                <td>{item.type === 'image' ? '이미지' : '비디오'}</td>
                <td>
                  <button
                    className={styles.button}
                    onClick={() => {
                      setSelectedAd(item);
                      clickOpenDialog(item.contentUrl);
                    }}>
                    확인하기
                  </button>
                </td>
                <td>{item.version}</td>
                <td>{dateChanger(item.registerDate)}</td>
                <td>{dateChanger(item.modifyDate)}</td>
                <td className={styles.modifyTd}>
                  <a
                    className={styles.modifyButton}
                    onClick={() => {
                      setSelectedAd(item);
                      setIsModalOpen(true);
                    }}>
                    수정
                  </a>
                  <a
                    className={styles.modifyButton}
                    onClick={() => {
                      deleteClick(item.id);
                    }}>
                    삭제
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.pagingContainer}>
          <Paging
            page={parseInt(intPage, 10)}
            countPerPage={10}
            pageRangeDisplayed={5}
            count={totalCount}
            setPage={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Advertisement;
