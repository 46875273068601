import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './AdvertisementModal.module.css';
import CloseButton from '../../resource/images/svg/CloseButton';
import { modifyAd, registerAd } from '../../network/api/MachineAPI';

let fileBlob = null;

const AdvertisementModal = ({
  advertisement,
  setIsModalOpen,
  setAd,
  number,
}) => {
  const [isActive, setIsActive] = useState(
    advertisement !== null ? advertisement.activated : false
  );
  const [type, setType] = useState(
    advertisement !== null ? advertisement.type : null
  );

  const [imageUrl, setImageUrl] = useState(
    advertisement !== null && advertisement.type === 'image'
      ? advertisement.contentUrl
      : null
  );
  const [videoUrl, setVideoUrl] = useState(
    advertisement !== null && advertisement.type === 'video'
      ? advertisement.contentUrl
      : null
  );
  const [time, setTime] = useState(
    advertisement !== null ? advertisement.exposureSecond : null
  );

  useEffect(() => {
    return () => {
      if (advertisement !== null) {
        URL.revokeObjectURL(imageUrl); // 사용이 끝나면 URL 해제
        URL.revokeObjectURL(videoUrl); // 사용이 끝나면 URL 해제
      }
    };
  }, [imageUrl, videoUrl]);

  const inputHandler = (e) => {
    let inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setTime(e.target.value);
    }
  };

  // 이미지 파일 선택 처리 (서버 업로드 없이 미리보기)
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const maxSizeInMB = 2; // 최대 2MB
    const allowedTypes = ['image/jpeg', 'image/png', 'image/webp']; // 허용되는 이미지 형식

    // 파일 크기 체크 (2MB 초과 시 alert)
    if (file.size > maxSizeInMB * 1024 * 1024) {
      alert('파일 용량이 2MB를 초과합니다.');
      return;
    }

    // 파일 형식 체크 (이미지 파일이 아닐 경우 alert)
    if (!allowedTypes.includes(file.type)) {
      alert(
        '허용되지 않는 파일 형식입니다. 이미지 파일만 업로드할 수 있습니다.'
      );
      return;
    }

    // 기존에 생성된 URL 해제
    if (imageUrl) {
      URL.revokeObjectURL(imageUrl);
    }

    fileBlob = file; // 이미지 파일 저장

    // 새로 생성된 로컬 URL로 미리보기 설정
    const imagePreviewUrl = URL.createObjectURL(file);
    setImageUrl(imagePreviewUrl);
  };

  // 동영상 파일 선택 처리 (서버 업로드 없이 미리보기)
  const handleVideoUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const maxSizeInMB = 50; // 최대 50MB
    const allowedTypes = ['video/mp4', 'video/webm', 'video/ogg']; // 허용되는 동영상 형식

    // 파일 크기 체크 (50MB 초과 시 alert)
    if (file.size > maxSizeInMB * 1024 * 1024) {
      alert('파일 용량이 50MB를 초과합니다.');
      return;
    }

    // 파일 형식 체크 (동영상 파일이 아닐 경우 alert)
    if (!allowedTypes.includes(file.type)) {
      alert(
        '허용되지 않는 파일 형식입니다. 동영상 파일만 업로드할 수 있습니다.'
      );
      return;
    }

    // 기존에 생성된 URL 해제
    if (videoUrl) {
      URL.revokeObjectURL(videoUrl);
    }

    fileBlob = file; // 동영상 파일 저장

    // 새로 생성된 로컬 URL로 미리보기 설정
    const videoPreviewUrl = URL.createObjectURL(file);
    setVideoUrl(videoPreviewUrl);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setAd(null);
  };

  const registerClick = async () => {
    if (imageUrl === null && videoUrl === null) {
      alert('광고 이미지 혹은 영상을 등록해 주세요');
      return;
    }
    if (type === null) {
      alert('광고 타입을 선택해 주세요');
      return;
    }
    if (type === 'image' && imageUrl === null) {
      alert('광고 이미지를 등록해 주세요');
      return;
    }
    if (type === 'video' && videoUrl === null) {
      alert('광고 영상을 등록해 주세요');
      return;
    }
    if (time === null || time === '') {
      alert('디스플레이 시간을 선택해 주세요');
      return;
    }

    const adInfo = {
      type: type,
      target: number,
      exposureSecond: parseInt(time),
    };

    const formData = new FormData();

    if (fileBlob !== null && typeof fileBlob !== 'string') {
      formData.append('multipartFile', fileBlob);
    }

    const machineInfoString = JSON.stringify(adInfo);

    // formData에 추가
    formData.append(
      'advertisementRequest',
      new Blob([machineInfoString], { type: 'application/json' })
    );
    const response = await registerAd(formData);
    if (response === 'success') {
      alert('등록 성공');
      window.location.reload(true);
    } else if (response === 'sizeOver') {
      alert('업로드 파일의 용량이 초과되었습니다');
    }
  };

  const modifyClick = async () => {
    if (imageUrl === null && videoUrl === null) {
      alert('광고 이미지 혹은 영상을 등록해 주세요');
      return;
    }
    if (type === null) {
      alert('광고 타입을 선택해 주세요');
      return;
    }
    if (type === 'image' && imageUrl === null) {
      alert('광고 이미지를 등록해 주세요');
      return;
    }
    if (type === 'video' && videoUrl === null) {
      alert('광고 영상을 등록해 주세요');
      return;
    }
    if (time === null || time === '') {
      alert('디스플레이 시간을 선택해 주세요');
      return;
    }

    const formData = new FormData();

    if (fileBlob !== null && typeof fileBlob !== 'string') {
      formData.append('multipartFile', fileBlob);
    }

    const machineInfoString = JSON.stringify({
      id: advertisement.id,
      activated: isActive,
      type: type,
      exposureSecond: parseInt(time),
    });

    // formData에 추가
    formData.append(
      'advertisementRequest',
      new Blob([machineInfoString], { type: 'application/json' })
    );

    const response = await modifyAd(formData);
    if (response === 'success') {
      alert('수정 성공');
      window.location.reload(true);
    } else if (response === 'sizeOver') {
      alert('업로드 파일의 용량이 초과되었습니다');
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div
          className={styles.dialogCloseButton}
          onClick={() => {
            closeModal();
          }}>
          <CloseButton />
        </div>
        <div className={styles.dialogContainer}>
          <div className={styles.title}>
            {advertisement !== null ? '광고수정' : '광고등록'}
          </div>
          {advertisement !== null && (
            <div className={styles.radioGroup}>
              <div
                className={styles.radioBox}
                onClick={() => {
                  setIsActive(true);
                }}>
                {isActive ? (
                  <div className={styles.selectedRadio}>
                    <div className={styles.selectedRadioInner} />
                  </div>
                ) : (
                  <div className={styles.unselectedRadio} />
                )}
                <div className={styles.radioText}>활성</div>
              </div>
              <div
                className={styles.radioBox}
                onClick={() => {
                  setIsActive(false);
                }}>
                {!isActive ? (
                  <div className={styles.selectedRadio}>
                    <div className={styles.selectedRadioInner} />
                  </div>
                ) : (
                  <div className={styles.unselectedRadio} />
                )}
                <div className={styles.radioText}>비활성</div>
              </div>
            </div>
          )}
          <div className={styles.radioGroup}>
            <div
              className={styles.radioBox}
              onClick={() => {
                setType('image');
              }}>
              {type === 'image' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>이미지</div>
            </div>
            <div
              className={styles.radioBox}
              onClick={() => {
                setType('video');
              }}>
              {type === 'video' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>비디오</div>
            </div>
          </div>

          {/* 이미지 렌더링 */}
          {imageUrl !== null && type === 'image' && (
            <img
              src={imageUrl}
              alt='미리보기 이미지'
              style={{ width: '600px' }}
            />
          )}

          {/* 동영상 렌더링 */}
          {videoUrl !== null && type === 'video' && (
            <video width='600' controls>
              <source src={videoUrl} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          )}

          {/* 업로드 버튼 (이미지 선택) */}
          {type === 'image' && (
            <div>
              <label htmlFor='imageUpload' className={styles.uploadButton}>
                이미지 업로드 (최대 2MB)
              </label>
              <input
                id='imageUpload'
                type='file'
                accept='image/*'
                onChange={handleImageUpload}
                className={styles.hiddenInput} // CSS로 숨기기
              />
            </div>
          )}

          {/* 업로드 버튼 (동영상 선택) */}
          {type === 'video' && (
            <div>
              <label htmlFor='videoUpload' className={styles.uploadButton}>
                동영상 업로드 (최대 50MB)
              </label>
              <input
                id='videoUpload'
                type='file'
                accept='video/*'
                onChange={handleVideoUpload}
                className={styles.hiddenInput} // CSS로 숨기기
              />
            </div>
          )}

          <div className={styles.inputTitle}>디스플레이 시간 (초)</div>
          <input
            type='text'
            maxLength={50}
            className={styles.inputId}
            placeholder='디스플레이 시간(초)을 입력해 주세요'
            onChange={inputHandler}
            value={time}
          />

          <div
            className={styles.button}
            onClick={() => {
              advertisement !== null ? modifyClick() : registerClick();
            }}>
            {advertisement !== null ? '수정하기' : '등록하기'}
          </div>
        </div>
      </div>
    </div>
  );
};

AdvertisementModal.propTypes = {
  advertisement: PropTypes.object,
  setIsModalOpen: PropTypes.func,
  setAd: PropTypes.func,
  number: PropTypes.string,
};

export default AdvertisementModal;
