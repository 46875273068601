import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import {
  getPlace,
  deletePlace,
  getPlaceByCategory,
  getPlaceByName,
  getPlaceByNameCategory,
} from '../../network/api/PlaceAPI';
import styles from './Place.module.css';
import ImageDialog from '../util/ImageDialog';
import Paging from '../util/Paging';
import PlaceRegisterDialog from './PlaceRegisterDialog';
import PlaceModifyDialog from './PlaceModifyDialog';
import { dateChanger } from '../../utils/converter';

const categoryList = [
  { value: '호텔', label: '호텔' },
  { value: '공원', label: '공원' },
  { value: '놀이터', label: '놀이터' },
  { value: '미용', label: '미용' },
  { value: '병원', label: '병원' },
  { value: '쇼핑', label: '쇼핑' },
  { value: '숙박', label: '숙박' },
  { value: '식당', label: '식당' },
  { value: '카페', label: '카페' },
  { value: '캠핑', label: '캠핑' },
  { value: '기타', label: '기타' },
];

const Place = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [placeList, setPlaceList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [imageUrlList, setImageUrlList] = useState(null);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [place, setPlace] = useState(null);
  const [isModifyDialogOpen, setIsModifyDialogOpen] = useState(false);
  const [isRegisterPlaceDialogOpen, setIsRegisterPlaceDialogOpen] =
    useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  // 페이지 번호는 정수로 변환, 기본값 1
  const intPage = new URLSearchParams(location.search).get('page') || 1;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const categoriesFromParams =
      queryParams.get('categories')?.split(',') || [];

    // 페이지 번호는 정수로 변환, 기본값 1
    const intPage = parseInt(queryParams.get('page'), 10) || 1;

    setSelectedCategories(categoriesFromParams);
    getPlaceInit(intPage, categoriesFromParams);
  }, [location.search]);

  const getPlaceInit = async (page, categoriesFromParams) => {
    let categoryQuery = categoriesFromParams.join(',');
    const param = {
      page: page, // intPage로 설정된 페이지가 아닌 인자로 넘겨받은 page 사용
      size: 10,
      ...(categoryQuery.length > 0
        ? { categoryList: categoryQuery }
        : { categoryList: [] }), // categoryQuery가 존재하면 추가
    };

    let response;
    if (searchValue !== '') {
      response = await getPlaceByNameCategory({
        page: intPage,
        size: 10,
        name: searchValue,
        categoryList: categoryQuery,
      });
    } else {
      response = await getPlaceByCategory(param);
    }

    if (response) {
      setPlaceList(response.content);
      setTotalCount(response.totalElements);
    }
  };

  const handleToggleCategoryList = (category) => {
    // 선택된 카테고리 업데이트
    const newSelectedCategories = selectedCategories.includes(category.value)
      ? selectedCategories.filter((item) => item !== category.value)
      : [...selectedCategories, category.value];

    const queryParams = new URLSearchParams(location.search);

    // 선택된 카테고리가 없을 경우 categories 쿼리 파라미터 삭제
    if (newSelectedCategories.length === 0) {
      queryParams.delete('categories');
    } else {
      queryParams.set('categories', newSelectedCategories.join(',')); // 카테고리 업데이트
    }

    // 네비게이션
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const handlePageChange = async (page) => {
    // 기본 URL 설정
    let url = '/place';

    // 현재 URL의 쿼리 파라미터를 가져옴
    const queryParams = new URLSearchParams(location.search);

    // page 파라미터를 업데이트
    if (page) {
      queryParams.set('page', page);
    }

    // categories 파라미터를 추가
    if (selectedCategories.length > 0) {
      queryParams.set('categories', selectedCategories.join(','));
    }

    // URL에 쿼리 파라미터 추가
    if (queryParams.toString()) {
      url += `?${queryParams.toString()}`;
    }

    // 이동
    navigate(url);
  };

  const clickOpenDialog = (imageUrlList) => {
    if (
      imageUrlList !== null &&
      imageUrlList[0] !== '' &&
      imageUrlList.length !== 0
    ) {
      setImageUrlList(imageUrlList);
      setIsImageDialogOpen(true);
    } else {
      alert('등록된 이미지가 없습니다');
    }
  };

  const clickDeleteDialog = (id) => {
    const result = window.confirm('정말 삭제하시겠습니까?');

    if (result) {
      deletePlaceFunc(id);
    }
  };

  const deletePlaceFunc = async (id) => {
    const response = await deletePlace({ id: id });
    if (response === 'success') {
      alert('삭제완료');
      getPlaceInit(); // 데이터를 새로고침하여 삭제된 항목을 반영
    } else {
      alert('삭제실패');
    }
  };

  const clickModifyDialog = (place) => {
    setPlace(place);
    setIsModifyDialogOpen(true);
  };

  const menu = categoryList.map((categoryItem, index) => (
    <div
      key={index}
      className={
        selectedCategories.includes(categoryItem.value)
          ? styles.slectedMenuItem
          : styles.menuItem
      }
      onClick={() => handleToggleCategoryList(categoryItem)}>
      {categoryItem.label}
    </div>
  ));

  return (
    <div className={styles.wrapper}>
      {isImageDialogOpen && imageUrlList !== null && (
        <ImageDialog
          setIsImageDialogOpen={setIsImageDialogOpen}
          imageUrlList={imageUrlList}
        />
      )}
      {isRegisterPlaceDialogOpen && (
        <PlaceRegisterDialog
          setIsRegisterPlaceDialogOpen={setIsRegisterPlaceDialogOpen}
        />
      )}
      {isModifyDialogOpen && (
        <PlaceModifyDialog
          place={place}
          setIsModifyDialogOpen={setIsModifyDialogOpen}
        />
      )}
      <div className={styles.innerWrapper}>
        <div className={styles.titleText}>장소 관리</div>
        <div className={styles.aboveContainer}>
          <div className={styles.scrollContainer}>{menu}</div>
        </div>
        <div className={styles.listContainer}>
          <div className={styles.totalCount}>전체 : {totalCount} 건</div>
          <div
            className={styles.button}
            onClick={() => setIsRegisterPlaceDialogOpen(true)}>
            추가하기
          </div>
        </div>
        <table className={styles.customTable}>
          <colgroup>
            <col style={{ minWidth: '120px' }} />
            <col style={{ minWidth: '170px' }} />
            <col style={{ minWidth: '170px' }} />
            <col style={{ minWidth: '200px' }} />
            <col style={{ minWidth: '110px' }} />
            <col style={{ minWidth: '110px' }} />
          </colgroup>
          <thead>
            <tr>
              <td>카테고리</td>
              <td>장소이름</td>
              <td>장소 이미지</td>
              <td>등록일</td>
              <td></td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {placeList.map((item, index) => (
              <tr key={index}>
                <td>{item.category}</td>
                <td>{item.name}</td>
                <td>
                  <button
                    className={styles.button}
                    onClick={() => clickOpenDialog(item.imageUrlList)}>
                    이미지 보기
                  </button>
                </td>
                <td>{dateChanger(item.registerDate)}</td>
                <td>
                  <button
                    className={styles.button}
                    onClick={() => clickModifyDialog(item)}>
                    수정
                  </button>
                </td>
                <td>
                  <button
                    className={styles.rbutton}
                    onClick={() => clickDeleteDialog(item.id)}>
                    삭제
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.pagingContainer}>
          <Paging
            page={parseInt(intPage, 10)}
            countPerPage={10}
            pageRangeDisplayed={5}
            count={totalCount}
            setPage={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Place;
